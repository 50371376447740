import { Injectable } from '@angular/core';
import { PageState } from '../models/PageState';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingSpinnerComponent } from '../components/spinner/spinner.component';

@Injectable()
export class LoadingService {
  constructor(public dialog: MatDialog) {}
  public open() {
    const dialogRef = this.dialog.open(LoadingSpinnerComponent, {
      disableClose: true,
      panelClass: 'loader-dialog',
    });
  }
  public close() {
    this.dialog.closeAll();
  }

  handlePageState = (pageState: PageState) => {
    if (pageState === PageState.Loading) {
      this.open();
    } else if (pageState === PageState.Loaded) {
      this.close();
    }
  };
}
