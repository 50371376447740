import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuService } from '../../services/menu.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-job-button',
  templateUrl: './new-job-button.component.html',
})
export class NewJobButtonComponent implements AfterViewInit {
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;
  private readonly menuId = 'newJob';
  public isOpen = false;

  constructor(
    private menuService: MenuService,
    private route: ActivatedRoute
  ) {
    this.menuService.menuEmitter.subscribe((id) => {
      if (id === this.menuId) {
        this.matMenuTrigger.openMenu();
      } else {
        this.matMenuTrigger.closeMenu();
      }
    });
  }

  ngAfterViewInit(): void {
    const term_id = this.route.snapshot.queryParamMap.get('term_id');
    if (term_id) {
      this.matMenuTrigger.openMenu();
    }
  }
  
  openMenu(): void {
    this.menuService.open(this.menuId);
    this.isOpen = true;
  }
}
