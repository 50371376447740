<div class="h-full w-full px-2 py-2">
  <div class="flex m-0">
    <button
      class="folder-tab m-0 !rounded-b-none !text-lg"
      [ngClass]="{ active: tabOpenState['term'] }"
      (click)="toggle('term')"
    >
      Terms
    </button>
    <button
      class="folder-tab !rounded-b-none !text-lg"
      [ngClass]="{ active: tabOpenState['org'] }"
      (click)="toggle('org')"
    >
      Organizations
    </button>
  </div>
  <div class="bg-[#809bce] py-2">
    <app-terms-view-container *ngIf="tabOpenState['term']"></app-terms-view-container>
    <app-orgs-view-container *ngIf="tabOpenState['org']"></app-orgs-view-container>
  </div>
</div>
