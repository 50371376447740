import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Term, FileList } from 'src/app/shared/models/Term';

import { MatMenu } from '@angular/material/menu';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-admin-attachment-file-upload',
  templateUrl: './admin-attachment-file-upload.component.html',
})
export class AdminAttachmentFileUploadComponent {
  @Output() outputOnUpload = new EventEmitter<any>();
  @Input() allTermData: Term[];
  @Input() fileUploadStatus: boolean;

  termDropdownForm: FormGroup;
  files: NgxFileDropEntry[] = [];
  fileLists: FileList[] = [];
  selectedTerm: Term | undefined;
  missingFiles: string[] = [];
  errors: string[] = [];
  uploadedFileNames: string[] = [];
  allFilesIncluded = false;

  reqFileObj = {};

  constructor(private matMenu: MatMenu) {}

  public dropped(files: NgxFileDropEntry[]) {
    if (files) this.files = [...this.files, ...files];
    else this.files = files;
  }

  closeMenu() {
    this.matMenu.closed.emit();
    this.files = [];
  }

  deleteFile(fileName: string) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].relativePath === fileName) {
        const file = this.files[i];
        this.uploadedFileNames.splice(this.uploadedFileNames.indexOf(file.relativePath), 1);
        this.files.splice(i, 1);
      }
    }
  }

  onSubmitFiles() {
    this.outputOnUpload.emit({
      files: this.files,
    });
    this.closeMenu();
  }
}
