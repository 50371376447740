import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { OrganizationAPIActions, OrganizationUIActions } from '../actions';
import { concatMap, map, catchError, of } from 'rxjs';
import { Organization } from '../../models/Organization';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService
  ) {}

  organizations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUIActions.GetAllOrganizations),
      concatMap(() => {
        return this.organizationService.getAllOrgList().pipe(
          map((results: Organization[]) => {
            return OrganizationAPIActions.GetAllOrganizationsSucess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(OrganizationAPIActions.GetAllOrganizationsError());
          })
        );
      })
    )
  );
  createOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUIActions.CreateNewOrganization),
      concatMap(({ org }) => {
        return this.organizationService.createOrg(org).pipe(
          map((results: Organization) => {
            return OrganizationAPIActions.CreateNewOrganizationSucess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(OrganizationAPIActions.CreateNewOrganizationError());
          })
        );
      })
    )
  );
  updateOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationUIActions.UpdateNewOrganization),
      concatMap((data) => {
        return this.organizationService.updateOrg(data).pipe(
          map((results: Organization) => {
            return OrganizationAPIActions.UpdateOrganizationSucess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(OrganizationAPIActions.UpdateOrganizationError());
          })
        );
      })
    )
  );
}
