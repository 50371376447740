import { IMenuItem, MenuItemTypes } from 'src/app/shared/models/IMenuItem';
import { BaseModuleRegistraion, IModuleRegistration } from 'src/app/shared/moduleRegistration/base.registration';

export const MessageRegistration: IModuleRegistration = class extends BaseModuleRegistraion {
  static readonly title = 'Messaging';
  static readonly breadcrumb = 'Messaging';

  static getMenu(): IMenuItem[] {
    return [
      {
        name: 'Messaging',
        type: MenuItemTypes.dropDown,
        state: 'message/',
        sub: [
          {
            name: 'inbox',
            type: MenuItemTypes.link,
            state: 'message/inbox',
          },
        ],
      },
    ];
  }
};
