import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Term } from '../../models/Term';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PageState } from '../../models/PageState';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-term-form',
  templateUrl: './term-form.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '150px',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '50px',
          opacity: 0.8,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class TermFormComponent {
  @Output() outputTermData = new EventEmitter<Term>();
  @Input() loadingStatus: PageState;
  isOpen = true;

  termForm = new FormGroup({
    inst_type: new FormControl(undefined, Validators.compose([Validators.required])),
    data_type: new FormControl(undefined, Validators.compose([Validators.required])),
    term_name: new FormControl('', Validators.compose([Validators.required])),
    week_num: new FormControl(undefined, Validators.compose([Validators.required])),
    early_sub_week_num: new FormControl(undefined, Validators.compose([Validators.required])),
  });
  submitTermUpdate(): void {
    this.outputTermData.emit({
      inst_type: this.termForm.get('inst_type').value,
      data_type: this.termForm.get('data_type').value,
      term_name: this.termForm.get('term_name').value,
      week_num: this.termForm.get('week_num').value,
      early_sub_week_num: this.termForm.get('early_sub_week_num').value,
    });
  }
}
