<div
  *ngIf="loadingStatus === 'Loading'"
  class="absolute h-full w-full bg-gray-200 opacity-75 z-10 flex justify-center items-center"
>
  <div class="flex gap-10 m-h-[80px] h-[80px] items-center">
    <div class="w-[25px] h-[25px] bg-white rounded-md" [@boxSize]="boxState[0]"></div>
    <div class="w-[25px] h-[25px] bg-white rounded-md" [@boxSize]="boxState[1]"></div>
    <div class="w-[25px] h-[25px] bg-white rounded-md" [@boxSize]="boxState[2]"></div>
  </div>
</div>
