import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { LayoutContainerComponent } from './containers/layout-container/layout-container.component';
import { SharedMaterialComponents } from './shared-material.module';
import { TopNavComponent } from './components/topnav/topnav.component';
import { LoadingSpinnerComponent } from './components/spinner/spinner.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { GlossaryTabComponent } from './components/glossary-tab/glossary-tab.component';
import { OrgsViewComponent } from './components/orgs-view/orgs-view.component';
import { OrgsViewContainerComponent } from './containers/orgs-view/orgs-view.container';
import { TermsViewContainerComponent } from './containers/terms-view/terms-view.container';
import { TermsViewComponent } from './components/terms-view/terms-view.component';
import { TermFormComponent } from './components/term-form/term-form.component';
import { TermFormContainerComponent } from './containers/term-form/term-form.container';
import { BackButtonComponent } from './components/back-button/back.component';
import { NewJobButtonComponent } from './components/new-job-button/new-job-button.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AdminFileUploadContainerComponent } from '../views/file-uploader/containers/admin-file-upload/admin-file-upload.container';
import { AdminFileUploadComponent } from '../views/file-uploader/components/admin-file-upload/admin-file-upload.component';
import { OrgFormComponent } from './components/org-form/org-form.component';
import { OrgFormContainerComponent } from './containers/org-form/org-form.container';
import { TypeThreeUploadButtonComponent } from './components/type-three-upload-button/type-three-upload-button.component';
import { AdminAttachmentFileUploadContainerComponent } from '../views/file-uploader/containers/admin-attachment-file-upload/admin-attachment-file-upload.container';
import { AdminAttachmentFileUploadComponent } from '../views/file-uploader/components/admin-attachment-file-upload/admin-attachment-file-upload.component';
import { NavOutDialogBoxComponent } from './components/nav-out-dialog-box/nav-out-dialog-box.component';
import { RefreshDialogComponent } from './components/refresh-dialog/refresh-dialog.component';

const components = [
  BaseLayoutComponent,
  TopNavComponent,
  LoadingSpinnerComponent,
  GlossaryTabComponent,
  OrgsViewComponent,
  TermsViewComponent,
  TermFormComponent,
  BackButtonComponent,
  NewJobButtonComponent,
  TypeThreeUploadButtonComponent,
  AdminFileUploadComponent,
  OrgFormComponent,
  AdminAttachmentFileUploadComponent,
  NavOutDialogBoxComponent,
  RefreshDialogComponent,
];
const containers = [
  LayoutContainerComponent,
  OrgsViewContainerComponent,
  TermsViewContainerComponent,
  TermFormContainerComponent,
  AdminFileUploadContainerComponent,
  OrgFormContainerComponent,
  AdminAttachmentFileUploadContainerComponent,
];
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialComponents,
    CommonModule,
    HttpClientModule,
    NgxFileDropModule,
    JsonPipe,
  ],
  declarations: [components, containers],
  exports: [components, containers],
})
export class SharedComponents {}
