import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../../shared/services/api.service';
import { ILoginParams, ILoginResponse } from '../models/login';
import { environment } from 'src/environments/environment';
import { IUserModel, User } from '../models/user';

@Injectable()
export class LoginService {
  constructor(private apiService: ApiService) {}
  readonly authRoot = '/dj-rest-auth';
  readonly allAuthRoot = '/accounts';
  readonly googleOauthRoot = '';
  readonly userRoot = '/users';

  public getNonAdminUserList(): Observable<User[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.userRoot, 'user_list/');
    return this.apiService.get(fullAPIUrl);
  }
  public postUserLogin(userPostQuery: ILoginParams): Observable<ILoginResponse> {
    const fullAPIUrl = this.apiService.buildFullURL(this.authRoot, 'login/');
    return this.apiService.post(fullAPIUrl, userPostQuery);
  }
  public postVerifyUser(): Observable<IUserModel> {
    const fullAPIUrl = this.apiService.buildFullURL(this.authRoot, 'token/verify/');
    return this.apiService.post(fullAPIUrl, {});
  }

  public postRefreshToken() {
    const fullAPIUrl = this.apiService.buildFullURL(this.authRoot, 'token/refresh/');
    return this.apiService.post(fullAPIUrl, {});
  }

  public googleOauthLogin(code: string) {
    const fullAPIUrl = this.apiService.buildFullURL(this.authRoot, 'google/');
    return this.apiService.post(fullAPIUrl, { code: code });
  }

  public getGoogleOauthIdToken() {
    const googleOauthURL = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${environment.redirect_uri}&prompt=consent&response_type=code&client_id=${environment.oAuthClientId}&scope=openid%20email%20profile&access_type=offline`;
    return googleOauthURL;
  }

  public logOut() {
    const fullAPIUrl = this.apiService.buildFullURL(this.authRoot, 'logout/');
    return this.apiService.post(fullAPIUrl, {});
  }

  public timeOut() {
    return of();
  }
}
