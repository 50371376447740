import { IMenuItem, MenuItemTypes } from 'src/app/shared/models/IMenuItem';
import { BaseModuleRegistraion, IModuleRegistration } from 'src/app/shared/moduleRegistration/base.registration';

export const ArchiveRegistration: IModuleRegistration = class extends BaseModuleRegistraion {
  static readonly title = 'Archive';
  static readonly breadcrumb = 'Archive';

  static getMenu(): IMenuItem[] {
    return [
      {
        name: 'Archive',
        type: MenuItemTypes.dropDown,
        state: 'archive/main',
        sub: [
          {
            name: 'Archive Dashboard',
            type: MenuItemTypes.link,
            state: 'archive/main',
          },
          {
            name: 'Year Report',
            type: MenuItemTypes.link,
            state: 'archive/year_report',
          },
          {
            name: 'Detail Report',
            type: MenuItemTypes.link,
            state: 'archive/archive_detail_report',
          },
        ],
      },
    ];
  }
};
