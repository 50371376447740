<app-term-form-container [scrollToLatest]="scrollToLatest"></app-term-form-container>
<mat-card
  class="term-card mt-2 mx-2 overflow-hidden"
  *ngFor="let term of allTermData; index as i"
  [@openClose]="termsState[term.id] ? 'open' : 'closed'"
>
  <mat-card-header>
    <div class="flex justify-between w-full">
      <mat-card-title [ngClass]="{ 'text-sm': '!termsState[term.id]' }">{{ term.term_name }}</mat-card-title>
      <div>
        <mat-icon *ngIf="termsState[term.id]" class="text-sm cursor-pointer">settings</mat-icon>
        <mat-icon *ngIf="termsState[term.id]" class="text-sm cursor-pointer" (click)="toggle(term.id)"
          >arrow_drop_up</mat-icon
        >
      </div>
      <mat-icon *ngIf="!termsState[term.id]" class="text-sm cursor-pointer" (click)="toggle(term.id)"
        >arrow_drop_down</mat-icon
      >
    </div>
  </mat-card-header>
  <mat-card-content>
    <table class="text-sm">
      <tr>
        <td class="whitespace-nowrap">Institution Type :</td>
        <td>{{ term?.inst_type }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Data Type :</td>
        <td>{{ term?.data_type }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Submit Week :</td>
        <td>{{ term?.week_num }}</td>
      </tr>

      <tr>
        <td class="whitespace-nowrap">Early Submit Wk :</td>
        <td>{{ term?.early_sub_week_num ? term?.early_sub_week_num : 'No value' }}</td>
      </tr>
    </table>
  </mat-card-content>
  <!-- <mat-card-footer>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer> -->
</mat-card>
