import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousUrlService } from '../../services/previous-url.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back.component.html',
})
export class BackButtonComponent {
  urlString: string;

  constructor(
    private router: Router,
    private previousUrlService: PreviousUrlService
  ) {}

  goBack(): void {
    const previousUrl = this.previousUrlService.getPreviousUrl();
    !previousUrl ? this.router.navigateByUrl('dashboard/main') : this.router.navigateByUrl(previousUrl);
  }
}
