import { Store } from '@ngrx/store';
import * as PinnedItemsSelector from '../state/selectors/pinned.selectors';
import { PinnedUIActions } from '../state/actions';
import { Injectable } from '@angular/core';
import { PinnedItemState } from '../state/reducers/pinned-ingestion.reducer';

@Injectable()
export class PinnedItemsFacade {
  pinnedIngestions$ = this.store.select(PinnedItemsSelector.selectPinnedIngestions);
  pinnedLoadingStatus$ = this.store.select(PinnedItemsSelector.selectLoadingStatus);

  constructor(public store: Store<PinnedItemState>) {}

  public getPinnedIngestions() {
    this.store.dispatch(PinnedUIActions.GetAllPinned());
  }
}
