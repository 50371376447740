import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { Term } from '../models/Term';

@Injectable()
export class TermService {
  constructor(private apiService: ApiService) {}
  readonly termRoot = '/terms';

  getAllTermList(): Observable<Term[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.termRoot, '');
    return this.apiService.get<Term[]>(fullAPIUrl);
  }

  createNewTerm(term: Term): Observable<Term> {
    const fullAPIUrl = this.apiService.buildFullURL(this.termRoot, '');
    return this.apiService.post<Term>(fullAPIUrl, term);
  }

  updateTerm(term: Term): Observable<Term> {
    const fullAPIUrl = this.apiService.buildFullURL(this.termRoot, `${term.id}/`);
    return this.apiService.patch<Term>(fullAPIUrl, term);
  }
}
