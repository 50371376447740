import { Action, on, createReducer } from '@ngrx/store';
import { Term } from '../../models/Term';
import * as SharedActions from '../actions';
import { PageState } from '../../models/PageState';

const initialState: TermDataState = {
  TermDataLoading: null,
  terms: [],
};

export interface TermDataState {
  TermDataLoading: PageState;
  terms: Term[];
}

export const reducer = createReducer(
  initialState,
  on(SharedActions.TermUIActions.GetAllTerms, (state, payload) => {
    return {
      ...state,
      terms: [],
      TermDataLoading: PageState.Loading,
    };
  }),
  on(SharedActions.TermAPIActions.GetAllTermsSucess, (state, payload) => {
    return {
      ...state,
      terms: payload.data,
      TermDataLoading: PageState.Loaded,
    };
  }),
  on(SharedActions.TermUIActions.CreateNewTerm, (state, payload) => {
    return {
      ...state,
      terms: state.terms,
      TermDataLoading: PageState.Loading,
    };
  }),
  on(SharedActions.TermAPIActions.CreateNewTermSuccess, (state, payload) => {
    return {
      ...state,
      terms: [...state.terms, payload.data],
      TermDataLoading: PageState.Loaded,
    };
  })
);

export function TermReducer(state: TermDataState, action: Action) {
  return reducer(state, action);
}
