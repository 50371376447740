<div (click)="$event.stopPropagation()" class="z-50 px-8 w-[600px] h-[350px] flex flex-col bg-athena-bg-dark-selected">
  <div class="flex flex-row justify-end">
    <button closeMenu (click)="closeMenu()" class="text-sm text-athena-bg-light-gry">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="flex justify-between">
    <h2 class="text-md text-yellow-400 font-bold mb-3">Upload Attachment File</h2>
  </div>
  <div class="bg-athena-bg-dark border-athena-bg-darker-light-gray border-2 border-dashed flex-grow mt-4 mb-8">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" class="admin-ngx-file-drop">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="h-full w-full flex flex-col relative">
          <div>
            <h2 class="text-white text-lg text-center font-bold mb-3">Upload File here</h2>
            <div
              *ngFor="let file of files"
              class="w-full h-[40px] px-5 mb-2 bg-athena-bg-dark-selected text-white text-sm rounded-md flex items-center justify-between"
            >
              <span>{{ file.relativePath }}</span>
              <div class="flex gap-5 items-center">
                <mat-progress-bar mode="indeterminate" class="!w-32" *ngIf="!fileUploadStatus"></mat-progress-bar>
                <mat-progress-bar
                  mode="determinate"
                  value="100"
                  class="!w-32"
                  *ngIf="fileUploadStatus"
                ></mat-progress-bar>
                <button (click)="deleteFile(file?.relativePath)" class="ml-2 text-red-500">Delete</button>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-end absolute top-52 left-[10px]">
            <p *ngFor="let error of errors" class="text-xs text-athena-orange font-bold whitespace-nowrap mr-3">
              {{ error }}
            </p>
            <button
              (click)="openFileSelector()"
              class="bg-athena-bg-clr rounded-md py-1 px-3 text-black hover:bg-black hover:text-white shadow-md"
            >
              Select Files
            </button>
            <button
              *ngIf="files.length > 0"
              (click)="onSubmitFiles()"
              class="bg-athena-bg-clr rounded-md py-1 px-3 text-black hover:bg-black hover:text-white shadow-md"
            >
              Submit Files
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>
