import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ILoginParams } from '../models/login';
import { AuthenticationUiActions } from '../state/actions';
import { AuthenticationState } from '../state/reducer/authentication.reducer';
import * as AuthenticationSelector from '../state/selectors/authentication.selectors';

@Injectable()
export class AuthenticationFacade {
  user$ = this.store.select(AuthenticationSelector.selectUser);
  userList$ = this.store.select(AuthenticationSelector.selectUserList);

  constructor(public store: Store<AuthenticationState>) {}

  public getNonAdminUserList() {
    this.store.dispatch(AuthenticationUiActions.GetNonAdminUserList());
  }

  public googleOauthLogin(googleOauthIdToken: string) {
    this.store.dispatch(AuthenticationUiActions.GoogleOauthLogUserIn({ outhIdToken: googleOauthIdToken }));
  }

  public logout() {
    this.store.dispatch(AuthenticationUiActions.LogUserOut());
  }

  public userTimeOut() {
    this.store.dispatch(AuthenticationUiActions.UserTimeOut());
  }
}
