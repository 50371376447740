import { Injectable } from '@angular/core';
import { ApiService, IRequestOptions } from '../../../shared/services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUploadPayload } from '../models/file-upload-payload';
import { Observable } from 'rxjs';

@Injectable()
export class FileUploaderService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}
  readonly fileUploadRoot = '/file_upload';

  readonly requestOptionDefaults: IRequestOptions = {
    withCredentials: true,
  };

  public receiveSignedUploadURL(fileNameArr: FileUploadPayload) {
    const fullAPIUrl = this.apiService.buildFullURL(this.fileUploadRoot, '');
    return this.apiService.post(fullAPIUrl, fileNameArr);
  }

  public sendAttachmentdataToFileUpload(formData: any) {
    const fullAPIUrl = this.apiService.buildFullURL(this.fileUploadRoot, 'type-3/');
    return this.apiService.post(fullAPIUrl, formData);
  }

  public uploadFileToGCP(signedUrl: string, file: File) {
    const fullAPIUrl = this.buildFullURLForBucketUpload(signedUrl, '');
    return this.customPut(fullAPIUrl, file);
  }

  public buildFullURLForBucketUpload(urlRoot: string, endpoint: string, queryParamObject?: object) {
    const searchParams = queryParamObject ? new URLSearchParams(Object.entries(queryParamObject)) : null;
    return `${urlRoot}${searchParams ? '?' + searchParams : ''}`;
  }

  public customPut<T>(url: string, body: object, options: IRequestOptions = this.requestOptionDefaults): Observable<T> {
    return this.http.put<T>(url, body, options);
  }
}
