import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class PinnedDataIngestionService {
  readonly dataIngestionRoot = '/data_ingestions';
  private pinnedDataIngestionInfoKey = 'pinnedDataIngestionInfo';

  constructor(private apiService: ApiService) {}

  getPinnedDataIngestionInfo(): Observable<DataIngestion[]> {
    const pinnedIds = this.getPinnedDataIngestionIds();
    // Make an API call to fetch details of pinned data ingestions
    const fullAPIUrl = this.apiService.buildFullURL(this.dataIngestionRoot, 'pinned/');
    return this.apiService.post<DataIngestion[]>(fullAPIUrl, { ids: pinnedIds });
  }

  togglePinnedDataIngestion(dataIngestionId: number): Observable<void | DataIngestion[]> {
    const pinnedIds = this.getPinnedDataIngestionIds();
    const index = pinnedIds.indexOf(dataIngestionId);
    if (index !== -1) {
      pinnedIds.splice(index, 1);
    } else {
      pinnedIds.push(dataIngestionId);
    }
    localStorage.setItem(this.pinnedDataIngestionInfoKey, JSON.stringify(pinnedIds));
    // Update the pinned data ingestion info after toggling
    return this.getPinnedDataIngestionInfo().pipe(
      tap((pinnedDataIngestions) => {
        // Update any component that uses the pinned data ingestion info
        // For example, you can emit an event or update a shared service
      })
    );
  }

  private getPinnedDataIngestionIds(): number[] {
    const pinnedIdsStr = localStorage.getItem(this.pinnedDataIngestionInfoKey);
    return pinnedIdsStr ? JSON.parse(pinnedIdsStr) : [];
  }
}
