import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly baseUrl = environment.apiURL;

  readonly requestOptionDefaults: IRequestOptions = {
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  buildFullURL(urlRoot: string, endpoint: string, queryParamObject?: object) {
    const baseUrl = `${urlRoot}/${endpoint}`;
    const searchParams = queryParamObject ? new URLSearchParams(Object.entries(queryParamObject)) : null;
    return `${baseUrl}${searchParams ? '?' + searchParams : ''}`;
  }

  public get<T>(endPoint: string, options: IRequestOptions = this.requestOptionDefaults): Observable<T> {
    return this.http.get<T>(this.baseUrl + endPoint, options);
  }

  public post<T>(endPoint: string, body: object, options: IRequestOptions = this.requestOptionDefaults): Observable<T> {
    return this.http.post<T>(this.baseUrl + endPoint, body, options);
  }

  public put<T>(endPoint: string, body: object, options: IRequestOptions = this.requestOptionDefaults): Observable<T> {
    return this.http.put<T>(this.baseUrl + endPoint, body, options);
  }

  public patch<T>(
    endPoint: string,
    body: object,
    options: IRequestOptions = this.requestOptionDefaults
  ): Observable<T> {
    return this.http.patch<T>(this.baseUrl + endPoint, body, options);
  }

  public delete<T>(endPoint: string, options: IRequestOptions = this.requestOptionDefaults): Observable<T> {
    return this.http.delete<T>(this.baseUrl + endPoint, options);
  }
}
