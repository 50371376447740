<div class="flex">
  <div class="top-nav-wrapper p-0 z-50 justify-between">
    <app-topnav
      [activatedParent]="activatedParent"
      [items]="allNavMenuItems"
      [navCollapsed]="navCollapsed"
      [pinnedList]="pinnedIngestions$ | async"
      [user]="user$ | async"
    ></app-topnav>
  </div>
  <div class="flex-col w-full">
    <mat-drawer-container class="" autosize>
      <mat-drawer #drawer class="w-[400px]" mode="side" position="end">
        <app-glossary-tab></app-glossary-tab>
      </mat-drawer>

      <div class="flex justify-end">
        <button
          *ngIf="userGroups[0]?.name === 'athena_admin'"
          class="sml-btn border-athena-bg-blue border-2 !bg-athena-bg-blue w-2 absolute top-14 h-24 text-white"
          (click)="drawer.toggle()"
        >
          <p class="rotate-[270deg] whitespace-nowrap p-0 m-0 relative top-10">Glossary Tab</p>
        </button>
      </div>
      <div class="h-screen w-full">
        <div class="w-full h-[50px] flex justify-between items-center">
          <div class="ml-4 flex items-center gap-4">
            <button (click)="navCollapsed = !navCollapsed">
              <mat-icon>{{ navCollapsed ? 'keyboard_double_arrow_right' : 'keyboard_double_arrow_left' }}</mat-icon>
            </button>
            <app-back-button></app-back-button>
            <app-new-job-button></app-new-job-button>
          </div>
          <div>
            <button mat-button [matMenuTriggerFor]="menu" class="flex flex-row-reverse">
              <span>Welcome, {{ userName?.split(' ')[0] }}! </span>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="adminStatus" mat-menu-item class="!text-xs">
                <a target="_blank" [href]="djangoAdminURL + '/users/user/' + userId + '/change/'">Account</a>
              </button>
              <button mat-menu-item (click)="logoutUser()" class="!text-xs">Logout</button>
            </mat-menu>
          </div>
        </div>
        <div class="m-auto h-[calc(100vh-50px)] w-full bg-athena-bg-clr">
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-drawer-container>
  </div>
</div>
