import { Component, Input } from '@angular/core';
import { Organization } from '../../models/Organization';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-orgs-view',
  templateUrl: './orgs-view.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          'max-height': '400px',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '50px',
          opacity: 0.8,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class OrgsViewComponent {
  @Input() allOrganizationData: Organization[];
  orgsState = {};

  toggle(id: number) {
    if (this.orgsState[id]) {
      this.orgsState[id] = !this.orgsState[id];
    } else {
      this.orgsState = {};
      this.orgsState[id] = true;
    }
  }
}
