import { AfterViewInit, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationFacade } from '../../facade/authentication.facade';

@Component({
  selector: 'app-login-container',
  templateUrl: './login.container.html',
})
export class LoginContainerComponent implements OnInit {
  public user$ = this.authenticationFacade.user$;

  authCode: string;

  constructor(
    private authenticationFacade: AuthenticationFacade,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.authCode = this.router.parseUrl(this.router.url).queryParamMap.get('code');
    if (this.authCode) {
      this.authenticationFacade.googleOauthLogin(this.authCode);
    }
  }
}
