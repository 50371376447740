import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './shared/components/base-layout/base-layout.component';
import { LayoutContainerComponent } from './shared/containers/layout-container/layout-container.component';
import { AuthenticationRoutes } from './views/authentication/authentication.routing';

const dynamicRoutes: Routes = [
  {
    path: 'tools',
    loadChildren: () => import('./views/notify-system/notify-system.module').then((m) => m.NotifySystemModule),
    data: { breadcrumb: { alias: 'Tools', label: 'Tools' } },
  },
  {
    path: 'upload',
    loadChildren: () => import('./views/file-uploader/file-uploader.module').then((m) => m.FileUploaderModule),
    data: { breadcrumb: { alias: 'Upload', label: 'Upload' } },
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { breadcrumb: { alias: 'Dashboard', label: 'Dashboard' } },
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./views/template-downloader/template-downloader.module').then((m) => m.FileDownloaderModule),
    data: { breadcrumb: { alias: 'Templates', label: 'Templates' } },
  },
  {
    path: 'message',
    loadChildren: () => import('./views/message/message.module').then((m) => m.MessageModule),
    data: { breadcrumb: { alias: 'Message', label: 'Message' } },
  },
  {
    path: 'archive',
    loadChildren: () => import('./views/archive/archive.module').then((m) => m.ArchiveModule),
    data: { breadcrumb: { alias: 'Archive', label: 'Archive' } },
  },
];

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: BaseLayoutComponent,
    children: AuthenticationRoutes,
  },

  {
    path: '',
    component: LayoutContainerComponent,
    children: dynamicRoutes,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(rootRouterConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
