import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-glossary-tab',
  templateUrl: './glossary-tab.component.html',
})
export class GlossaryTabComponent {
  tabOpenState = {
    term: true,
  };

  toggle(option: string) {
    if (this.tabOpenState[option]) {
      this.tabOpenState[option] = !this.tabOpenState[option];
    } else {
      this.tabOpenState = { term: false };
      this.tabOpenState[option] = true;
    }
  }
}
