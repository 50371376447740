import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IdleUserService } from './shared/services/idle-user.service';
import { MatDialog } from '@angular/material/dialog';
import { RefreshDialogComponent } from './shared/components/refresh-dialog/refresh-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'dga_frontend';
  isUserIdle = false;

  constructor(
    public dialog: MatDialog,
    private idleUserService: IdleUserService
  ) {}

  ngOnInit(): void {
    this.idleUserService.userInactive.subscribe((d) => (this.isUserIdle = d));
  }

  openDialog() {
    this.dialog.open(RefreshDialogComponent);
  }

  reset() {
    this.isUserIdle = false;
    this.idleUserService.reset();
  }
}
