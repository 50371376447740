import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SharedComponents } from './shared-components.module';
import { SharedMaterialComponents } from './shared-material.module';
import { ApiService } from './services/api.service';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from './services/notification.service';
import { LoadingService } from './services/loading.service';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { sharedStateKey } from './state';
import { OrganizationReducer } from './state/reducers/organization.reducer';
import { OrganizationFacade } from './facades/organization-facade';
import { OrganizationEffects } from './state/effects/organization.effects';
import { EffectsModule } from '@ngrx/effects';
import { OrganizationService } from './services/organization.service';
import { TermFacade } from './facades/term-facade';
import { TermService } from './services/terms.service';
import { TermReducer } from './state/reducers/term.reducer';
import { TermEffects } from './state/effects/term.effects';
import { PreviousUrlService } from './services/previous-url.service';
import { FileUploaderService } from '../views/file-uploader/services/file-uploader.service';
import { DataIngestionService } from '../views/dashboard/services/data-ingestion.service';
import { IInstanceService } from '../views/dashboard/services/ingestion-instance.service';
import { DataIngestionFacade } from '../views/dashboard/facade/data-ingestion.facade';
import { PinnedReducer } from './state/reducers/pinned-ingestion.reducer';
import { PinnedItemsFacade } from './facades/pinned-facade';
import { PinnedItemsEffects } from './state/effects/pinned.effects';
import { AuthGaurdService } from './services/auth-gaurd.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './services/auth-token.interceptor';
import { IdleUserService } from './services/idle-user.service';

const moduleEffects = [OrganizationEffects, TermEffects, PinnedItemsEffects];

const moduleReducers = {
  organizationsState: OrganizationReducer,
  termsState: TermReducer,
  pinnedState: PinnedReducer,
};

@NgModule({
  imports: [
    StoreModule.forFeature(sharedStateKey, moduleReducers),
    EffectsModule.forFeature(moduleEffects),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedComponents,
    SharedMaterialComponents,
  ],

  exports: [CommonModule, FormsModule, ReactiveFormsModule, SharedComponents, SharedMaterialComponents],
  providers: [
    ApiService,
    NavigationService,
    NotificationService,
    LoadingService,
    OrganizationFacade,
    OrganizationService,
    TermFacade,
    TermService,
    PreviousUrlService,
    FileUploaderService,
    DataIngestionService,
    IInstanceService,
    DataIngestionFacade,
    PinnedItemsFacade,
    AuthGaurdService,
    IdleUserService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true },
  ],
})
export class SharedModule {}
