import { Component, OnInit } from '@angular/core';
import { AuthGaurdService } from '../../services/auth-gaurd.service';

@Component({
  selector: 'app-refresh-dialog',
  templateUrl: './refresh-dialog.component.html',
})
export class RefreshDialogComponent {
  constructor(private authguardService: AuthGaurdService) {}
  public refreshBrowser(): void {
    this.authguardService.canActivate().subscribe((response) => {
      if (response) {
        if (response === true) {
          location.reload();
        } else {
          setTimeout(() => {
            location.reload();
          }, 500);
        }
      }
    });
  }

  public stopPropagation(event: MouseEvent): void {
    event.stopPropagation();
  }
}
