import { createAction, props } from '@ngrx/store';
import { DataIngestion } from '../../models/data-ingestion';
import { IngestionInstance } from '../../models/ingestion-instance';

enum DataIngestionsApiActionTypes {
  GetAllDataIngestionsSuccess = '[Ingestion API] Get GCP Workflows Success',
  GetAllDataIngestionsError = '[Ingestion API] Get GCP Workflows Error',

  GetSingleDataIngestionSuccess = '[Ingestion API] Get Single GCP Workflow Success',
  GetSingleDataIngestionError = '[Ingestion API] Get Single GCP Workflow Error',

  GetAllIInstancesSuccess = '[Instance API] Get All Ingestion Instances Success',
  GetAllIInstancesError = '[Instance API] Get All Ingestion Instances Error',

  GetUserIInstancesSuccess = '[Instance API] Get User Owned Ingestion Instances Success',
  GetUserIInstancesError = '[Instance API] Get User Owned Ingestion Instances Error',

  PatchIInstanceSuccess = '[Instance API] Patch Ingestion Instance Success',
  PatchIInstanceError = '[Instance API] Patch Ingestion Instance Error',

  GetOrgOwnedIInstancesSuccess = '[Instance API] Org Owned Ingestion Instances Success',
  GetOrgOwnedIInstancesError = '[Instance API] Get Org Owned Ingestion Instances Error',

  PatchIngestionSuccess = '[Ingestion API] Patch Ingestion Success',
  PatchIngestionError = '[Ingestion API] Patch Ingestion Error',

  FlipLoadingStateSuccess = '[Ingestion API] Flip Loading Status Success',
  FlipLoadingStateError = '[Ingestion API] Flip Loading Status Error',
}

export const GetAllDataIngestionsSuccess = createAction(
  DataIngestionsApiActionTypes.GetAllDataIngestionsSuccess,
  props<{ data: DataIngestion[] }>()
);
export const GetAllDataIngestionsError = createAction(DataIngestionsApiActionTypes.GetAllDataIngestionsError);

export const GetAllIInstancesSuccess = createAction(
  DataIngestionsApiActionTypes.GetAllIInstancesSuccess,
  props<{ data: IngestionInstance[] }>()
);
export const GetAllIInstancesError = createAction(DataIngestionsApiActionTypes.GetAllIInstancesError);

export const GetUserIInstancesSuccess = createAction(
  DataIngestionsApiActionTypes.GetUserIInstancesSuccess,
  props<{ data: IngestionInstance[] }>()
);
export const GetUserIInstancesError = createAction(DataIngestionsApiActionTypes.GetUserIInstancesError);

export const GetOrgOwnedIInstancesSuccess = createAction(
  DataIngestionsApiActionTypes.GetOrgOwnedIInstancesSuccess,
  props<{ data: IngestionInstance[] }>()
);
export const GetOrgOwnedIInstancesError = createAction(DataIngestionsApiActionTypes.GetOrgOwnedIInstancesError);

export const PatchIInstanceSuccess = createAction(
  DataIngestionsApiActionTypes.PatchIInstanceSuccess,
  props<{ data: IngestionInstance }>()
);
export const PatchIInstanceError = createAction(DataIngestionsApiActionTypes.PatchIInstanceError);

export const PatchIngestionSuccess = createAction(
  DataIngestionsApiActionTypes.PatchIngestionSuccess,
  props<{ data: DataIngestion }>()
);
export const PatchIngestionError = createAction(DataIngestionsApiActionTypes.PatchIngestionError);

export const GetSingleDataIngestionSuccess = createAction(
  DataIngestionsApiActionTypes.GetSingleDataIngestionSuccess,
  props<{ data: DataIngestion }>()
);

export const GetSingleDataIngestionError = createAction(DataIngestionsApiActionTypes.GetSingleDataIngestionError);

export const FlipLoadingStateSuccess = createAction(DataIngestionsApiActionTypes.FlipLoadingStateSuccess);

export const FlipLoadingStateError = createAction(DataIngestionsApiActionTypes.FlipLoadingStateError);
