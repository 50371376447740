import { Action, createReducer, on } from '@ngrx/store';
import { PageState } from '../../../../shared/models/PageState';
import * as AuthenticationActions from '../actions/';
import { User } from 'src/app/views/authentication/models/user';

const initialState: AuthenticationState = {
  loginPageState: null,
  user: undefined,
  userList: [],
};

export interface AuthenticationState {
  user: User | null;
  userList: User[];
  loginPageState: PageState | null;
}

export const authenticationState = createReducer(
  initialState,
  on(AuthenticationActions.AuthenticationUiActions.LogUserIn, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loading,
    };
  }),
  on(AuthenticationActions.AuthenticationUiActions.GoogleOauthLogUserIn, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loading,
    };
  }),
  on(AuthenticationActions.AuthenticationUiActions.GetNonAdminUserList, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loading,
      userList: [],
    };
  }),
  on(AuthenticationActions.AuthenticationApiActions.LogUserInSuccess, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loaded,
      user: payload.user,
    };
  }),
  on(AuthenticationActions.AuthenticationApiActions.LogUserOutSuccess, (state, payload) => {
    return {
      ...state,
      user: undefined,
    };
  }),
  on(AuthenticationActions.AuthenticationApiActions.UserTimeOutSuccess, (state, payload) => {
    return {
      ...state,
      user: undefined,
    };
  }),
  on(AuthenticationActions.AuthenticationApiActions.GoogleOauthLogUserInSuccess, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loaded,
      user: payload.user,
    };
  }),
  on(AuthenticationActions.AuthenticationApiActions.GetNonAdminUserListSuccess, (state, payload) => {
    return {
      ...state,
      loginPageState: PageState.Loaded,
      userList: payload.data,
    };
  })
);

export function AuthenticationReducer(state: AuthenticationState = initialState, action: Action): AuthenticationState {
  return authenticationState(state, action);
}
