import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-type-three-upload-button',
  templateUrl: './type-three-upload-button.component.html',
})
export class TypeThreeUploadButtonComponent {
  @ViewChild('menuTrigger') matMenuTrigger: MatMenuTrigger;
  private readonly menuId = 'typeThreeUploadButton';
  constructor(private menuService: MenuService) {
    this.menuService.menuEmitter.subscribe((id) => {
      if (id === this.menuId) {
        this.matMenuTrigger.openMenu();
      } else {
        this.matMenuTrigger.closeMenu();
      }
    });
  }

  openMenu(): void {
    this.menuService.open(this.menuId);
  }
}
