import { Component, Input } from '@angular/core';
import { Term } from '../../models/Term';
import { TermFacade } from '../../facades/term-facade';

@Component({
  selector: 'app-term-form-container',
  templateUrl: './term-form.container.html',
})
export class TermFormContainerComponent {
  @Input() scrollToLatest: () => void;
  termDataLoadingStatus$ = this.termFacade.termLoadingStatus$;

  constructor(private termFacade: TermFacade) {}

  public submitTermUpdate(term: Term) {
    this.termFacade.createNewTerm(term);
    setTimeout(this.scrollToLatest, 500);
  }
}
