import { createAction, props } from '@ngrx/store';
import { Organization } from '../../models/Organization';

enum OrganizationUIActionTypes {
  GetAllOrganizations = '[Organization UI] Get All Organizations',
  CreateNewOrganization = '[Organization UI] Create A New Organization',
  UpdateNewOrganization = '[Organization UI] Update An Exisiting Organization',
}

export const GetAllOrganizations = createAction(OrganizationUIActionTypes.GetAllOrganizations);
export const CreateNewOrganization = createAction(
  OrganizationUIActionTypes.CreateNewOrganization,
  props<{ org: Organization }>()
);
export const UpdateNewOrganization = createAction(
  OrganizationUIActionTypes.UpdateNewOrganization,
  props<{ data: object }>()
);
