import { createSelector } from '@ngrx/store';
import { selectDataIngestionState } from '../index';
import { DataIngestionState } from '../reducers/data-ingestion.reducer';
import { IngestionInstance } from '../../models/ingestion-instance';

export const selectDataIngestions = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.dataIngestions
);

export const selectAllIInstances = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.ingestionInstances
);
export const selectUserIInstances = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.userOwnedIInstances
);
export const selectOrgIInstances = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.orgOwnedIInstances
);

export const selectSingleDataIngestion = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.singleDataIngestion
);

export const selectDataIngestionLoading = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.DataIngestionLoading
);

export const selectSearchWord = createSelector(
  selectDataIngestionState,
  (state: DataIngestionState) => state.searchWord
);
