import { IMenuItem, MenuItemTypes } from 'src/app/shared/models/IMenuItem';
import { BaseModuleRegistraion, IModuleRegistration } from 'src/app/shared/moduleRegistration/base.registration';

export const NotifySystemRegistration: IModuleRegistration = class extends BaseModuleRegistraion {
  static readonly title = 'Tools';
  static readonly breadcrumb = 'Tools';

  static getMenu(): IMenuItem[] {
    return [
      {
        name: 'Tools',
        type: MenuItemTypes.dropDown,
        state: 'tools/main',
      },
    ];
  }
};
