import { createAction, props } from '@ngrx/store';
import { Term } from '../../models/Term';

enum TermApiActionTypes {
  GetAllTermsSucess = '[Term API] Get Terms Sucess',
  GetAllTermsError = '[Term API] Get Terms Error',
  CreateNewTermSuccess = '[Term API] Create New Term Success',
  CreateNewTermError = '[Term API] Create New Term Error',
}

export const GetAllTermsSucess = createAction(TermApiActionTypes.GetAllTermsSucess, props<{ data: Term[] }>());
export const GetAllTermsError = createAction(TermApiActionTypes.GetAllTermsError);

export const CreateNewTermSuccess = createAction(TermApiActionTypes.CreateNewTermSuccess, props<{ data: Term }>());
export const CreateNewTermError = createAction(TermApiActionTypes.CreateNewTermError);
