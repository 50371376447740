import { createAction, props } from '@ngrx/store';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';

enum PinnedApiActionTypes {
  GetAllPinnedIngestionsSucess = '[Pinned Items API] Get Pinned Ingestions Sucess',
  GetAllPinnedIngestionsError = '[Pinned Items API] Get Pinned Ingestions Error',
}

export const GetAllPinnedIngestionsSucess = createAction(
  PinnedApiActionTypes.GetAllPinnedIngestionsSucess,
  props<{ data: DataIngestion[] }>()
);
export const GetAllPinnedIngestionsError = createAction(PinnedApiActionTypes.GetAllPinnedIngestionsError);
