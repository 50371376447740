import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ILoginParams } from '../../models/login';
import { LoginService } from '../../services/login.service';
import { User } from '../../models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnChanges {
  @Input() user: User;
  @Output() outputSubmitUserCreds = new EventEmitter<ILoginParams>();

  constructor(
    public router: Router,
    private loginService: LoginService
  ) {}

  userCredentialForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required])),
    password: new FormControl('', Validators.compose([Validators.required])),
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user) {
      this.router.navigate(['dashboard/main']);
    }
  }

  ssoSignin(): void {
    window.location.href = this.loginService.getGoogleOauthIdToken();
  }
}
