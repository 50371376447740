<mat-card class="mt-2 mx-2 overflow-hidden" [@openClose]="isOpen ? 'open' : 'closed'">
  <form [formGroup]="orgForm" (ngSubmit)="submitOrgForm()">
    <mat-card-header>
      <div class="flex justify-between w-full">
        <mat-card-title [ngClass]="{ 'text-sm': '!termsState[term.id]' }">
          <input type="text" formControlName="long_name" placeholder="Long Org Name" />
        </mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <table class="text-sm">
        <tr>
          <td class="whitespace-nowrap">Code :</td>
          <td>
            <input type="text" formControlName="short_name" placeholder="ACME" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inst ID :</td>
          <td>
            <input type="number" formControlName="i_id" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inst SAS Code :</td>
          <td>
            <input type="text" formControlName="i_sas_name" placeholder="ACME" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inst Num :</td>
          <td>
            <input type="number" formControlName="i_num" placeholder="ACME" />
          </td>
        </tr>

        <tr>
          <td class="whitespace-nowrap">Inst Banner :</td>
          <td>
            <input type="text" formControlName="i_banner" placeholder="D" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inst OPEID :</td>
          <td>
            <input type="text" formControlName="i_opeid" placeholder="0000000" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inst Type :</td>
          <td>
            <input type="text" formControlName="i_inst_type" placeholder="T" />
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Inactive :</td>
          <td>
            <!-- this part needs to be only available when updating -->
            <input type="radio" formControlName="inactive" />
          </td>
        </tr>
      </table>
      <button type="submit">submit</button>
    </mat-card-content>
  </form>
</mat-card>
