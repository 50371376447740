import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as DataIngestionSelector from '../state/selectors/data-ingestion.selectors';
import { DataIngestionState } from '../state/reducers/data-ingestion.reducer';
import { DataIngestionsUIActions } from '../state/actions';
import { IngestionInstance } from '../models/ingestion-instance';

@Injectable()
export class DataIngestionFacade {
  allIngestionData$ = this.store.select(DataIngestionSelector.selectDataIngestions);
  allIInstances$ = this.store.select(DataIngestionSelector.selectAllIInstances);
  userIInstances$ = this.store.select(DataIngestionSelector.selectUserIInstances);
  orgIInstances$ = this.store.select(DataIngestionSelector.selectOrgIInstances);
  singleDataIngestion$ = this.store.select(DataIngestionSelector.selectSingleDataIngestion);
  dataIngestionLoading$ = this.store.select(DataIngestionSelector.selectDataIngestionLoading);
  searchWord$ = this.store.select(DataIngestionSelector.selectSearchWord);

  constructor(public store: Store<DataIngestionState>) {}

  public getAllIngestions() {
    this.store.dispatch(DataIngestionsUIActions.GetAllDataIngestions());
  }

  public getAllIInstances(data_type: string) {
    this.store.dispatch(DataIngestionsUIActions.GetAllIInstances({ data_type }));
  }

  public flipLoadingState() {
    this.store.dispatch(DataIngestionsUIActions.GetLoadingState());
  }

  public getUserIInstances(user_id: number) {
    this.store.dispatch(DataIngestionsUIActions.GetUserOwnedIInstances({ user_id }));
  }

  public getOrgIInstances(org_id: number) {
    this.store.dispatch(DataIngestionsUIActions.GetOrgOwnedIInstances({ org_id }));
  }

  public patchIInstance(jsonPayload: object) {
    this.store.dispatch(DataIngestionsUIActions.PatchIInstance({ jsonPayload }));
  }

  public getSingleIngestion(ingestion_id: number) {
    this.store.dispatch(DataIngestionsUIActions.GetSingleDataIngestion({ ingestion_id }));
  }

  public getWorkflowSearchWord(search_word: string) {
    this.store.dispatch(DataIngestionsUIActions.GetIngestionSearchWord({ search_word }));
  }

  public patchDataIngestion(jsonPayload: object) {
    this.store.dispatch(DataIngestionsUIActions.PatchIngestion({ jsonPayload }));
  }
}
