import { Router } from '@angular/router';
import { TermFacade } from 'src/app/shared/facades/term-facade';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationFacade } from 'src/app/views/authentication/facade/authentication.facade';
import { User } from 'src/app/views/authentication/models/user';
import { FileUploaderService } from '../../services/file-uploader.service';
import { FileParams } from 'src/app/views/dashboard/models/upload-file-params';
import { Component, ViewChild } from '@angular/core';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';
import { AdminFileUploadComponent } from '../../components/admin-file-upload/admin-file-upload.component';

@Component({
  selector: 'app-admin-attachment-file-upload-container',
  templateUrl: './admin-attachment-file-upload.container.html',
})
export class AdminAttachmentFileUploadContainerComponent {
  @ViewChild(AdminFileUploadComponent) childComponent: AdminFileUploadComponent;
  constructor(
    public notificationService: NotificationService,
    private authenticationFacade: AuthenticationFacade,
    private termFacade: TermFacade,
    public router: Router,
    private fileUploaderService: FileUploaderService
  ) {}

  private user$ = this.authenticationFacade.user$;
  public user: User;
  public allTermData$ = this.termFacade.allTermData$;
  public fileUploadStatus: boolean;

  ngOnInit(): void {
    this.user$.subscribe((d) => {
      this.user = d;
    });
  }

  public async onUpload(payload: FileParams) {
    const formData = new FormData();
    for (const droppedFile of payload.files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        // Convert fileEntry.file() to a promise for await this fixed issue
        const file = await new Promise<File>((resolve, reject) => {
          fileEntry.file(
            (file: File) => {
              resolve(file);
            },
            (error: any) => {
              reject(error);
            }
          );
        });
        formData.append('files', file, droppedFile.relativePath);
      }
    }

    this.fileUploaderService.sendAttachmentdataToFileUpload(formData).subscribe((response) => {
      if (response && response['response'] === 'success') {
        this.notificationService.openSuccessSnackBar('File has been uploaded to the bucket');
        this.fileUploadStatus = true;
        setTimeout(() => {
          this.closeMenu();
        }, 1000);
      } else {
        this.notificationService.openErrorSnackBar('Failed to upload file');
      }
    });
  }

  closeMenu() {
    this.childComponent.closeMenu();
  }
}
