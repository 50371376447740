<mat-card class="mt-2 mx-2 overflow-hidden" [@openClose]="isOpen ? 'open' : 'closed'">
  <form [formGroup]="termForm" (ngSubmit)="submitTermUpdate()">
    <mat-card-header>
      <div class="flex justify-between w-full">
        <mat-card-title>
          <input type="text" formControlName="term_name" placeholder="Create New Term" />
        </mat-card-title>
        <div>
          <button type="submit">
            <mat-icon *ngIf="isOpen" class="text-sm cursor-pointer">create</mat-icon>
          </button>
          <mat-icon *ngIf="!isOpen" (click)="isOpen = !isOpen" class="text-sm cursor-pointer">arrow_drop_up</mat-icon>
        </div>
        <mat-icon *ngIf="isOpen" (click)="isOpen = !isOpen" class="text-sm cursor-pointer">arrow_drop_down</mat-icon>
      </div>
    </mat-card-header>
    <mat-card-content>
      <table class="text-sm">
        <tr>
          <td class="whitespace-nowrap">Institution Type :</td>
          <td>
            <select formControlName="inst_type">
              <option value="dgi">DGI</option>
              <option value="tech">Tech</option>
              <option value="both">Both</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="whitespace-nowrap">Data Type :</td>
          <td>
            <select formControlName="data_type">
              <option value="type_1">Type 1</option>
              <option value="type_2">Type 2</option>
              <option value="type_3">Type 3</option>
              <option value="type_4">Type 4</option>
              <option value="type_5">Type 5</option>
            </select>
          </td>
        </tr>

        <tr>
          <td class="whitespace-nowrap">Submit Week :</td>
          <td>
            <input type="number" min="1" max="52" formControlName="week_num" />
          </td>
        </tr>

        <tr>
          <td class="whitespace-nowrap">Early Submit Wk :</td>
          <td>
            <input type="number" min="1" max="52" formControlName="early_sub_week_num" />
          </td>
        </tr>
      </table>
    </mat-card-content>
  </form>
  <mat-card-footer *ngIf="loadingStatus === 'Loading'">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
