import { Action, on, createReducer } from '@ngrx/store';
import * as SharedActions from '../actions';
import { PageState } from '../../models/PageState';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';

const initialState: PinnedItemState = {
  pinnedItemLoading: null,
  pinnedIngestionArr: [],
};

export interface PinnedItemState {
  pinnedItemLoading: PageState;
  pinnedIngestionArr: DataIngestion[];
}

export const reducer = createReducer(
  initialState,
  on(SharedActions.PinnedUIActions.GetAllPinned, (state, payload) => {
    return {
      ...state,
      pinnedIngestionArr: [],
      pinnedItemLoading: PageState.Loading,
    };
  }),
  on(SharedActions.PinnedAPIActions.GetAllPinnedIngestionsSucess, (state, payload) => {
    return {
      ...state,
      pinnedIngestionArr: payload.data,
      pinnedItemLoading: PageState.Loaded,
    };
  })
);

export function PinnedReducer(state: PinnedItemState, action: Action) {
  return reducer(state, action);
}
