import { Observable } from 'rxjs';
import { IMenuItem } from '../models/IMenuItem';

export class BaseModuleRegistraion {}

export interface IModuleRegistration {
  title: string;
  breadcrumb: string;
  getMenu(): IMenuItem[];
}

export interface IModuleRegistrationForProjects {
  title: string;
  breadcrumb: string;
  getMenu(): Observable<IMenuItem[]>;
}
