import { Store } from '@ngrx/store';
import * as OrganizationDataSelector from '../state/selectors/organization.selectors';
import { OrganizationDataState } from '../state/reducers/organization.reducer';
import { OrganizationUIActions } from '../state/actions';
import { Injectable } from '@angular/core';
import { Organization } from '../models/Organization';

@Injectable()
export class OrganizationFacade {
  allOrganizationData$ = this.store.select(OrganizationDataSelector.selectOrganizations);
  orgLoadingStatus$ = this.store.select(OrganizationDataSelector.selectLoadingStatus);

  constructor(public store: Store<OrganizationDataState>) {}

  public getAllOrganizations() {
    this.store.dispatch(OrganizationUIActions.GetAllOrganizations());
  }
  public createOrg(org: Organization) {
    this.store.dispatch(OrganizationUIActions.CreateNewOrganization({ org }));
  }
  public updateOrg(data: object) {
    this.store.dispatch(OrganizationUIActions.UpdateNewOrganization({ data }));
  }
}
