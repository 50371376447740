import { Store } from '@ngrx/store';
import * as TermDataSelector from '../state/selectors/term.selectors';
import { TermDataState } from '../state/reducers/term.reducer';
import { TermUIActions } from '../state/actions';
import { Injectable } from '@angular/core';
import { Term } from '../models/Term';

@Injectable()
export class TermFacade {
  allTermData$ = this.store.select(TermDataSelector.selectTerms);
  termLoadingStatus$ = this.store.select(TermDataSelector.selectLoadingStatus);

  constructor(public store: Store<TermDataState>) {}

  public getAllTerms() {
    this.store.dispatch(TermUIActions.GetAllTerms());
  }
  public createNewTerm(term: Term) {
    this.store.dispatch(TermUIActions.CreateNewTerm({ term }));
  }
}
