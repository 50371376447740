import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { IMenuItem } from '../../models/IMenuItem';
import { AuthenticationFacade } from 'src/app/views/authentication/facade/authentication.facade';
import { Observable, filter } from 'rxjs';
import { OrganizationFacade } from '../../facades/organization-facade';
import { TermFacade } from '../../facades/term-facade';
import { PinnedDataIngestionService } from '../../services/pinned-data-ingestions.service';
import { PinnedItemsFacade } from '../../facades/pinned-facade';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/views/authentication/models/user';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
})
export class LayoutContainerComponent implements OnInit {
  allNavMenuItems: IMenuItem[] = [];
  AllSideMenuItems: IMenuItem[] = [];
  activatedParent: IMenuItem;
  userEmail: string;
  userName: string;
  userGroups = [];
  user$ = this.authenticationFacade.user$;
  organizations$ = this.organizationFacade.allOrganizationData$;
  terms$ = this.termFacade.allTermData$;
  showGlossaryTab = false;
  navCollapsed = false;
  userId: number;
  adminStatus: boolean;
  pinnedIngestions$ = this.pinnedItemsFacade.pinnedIngestions$;
  djangoAdminURL = environment.apiURL.slice(0, environment.apiURL.length - 2) + 'admin';

  constructor(
    private navigationService: NavigationService,
    private authenticationFacade: AuthenticationFacade,
    private organizationFacade: OrganizationFacade,
    private termFacade: TermFacade,
    public router: Router,
    private pinnedItemsFacade: PinnedItemsFacade
  ) {}

  ngOnInit(): void {
    this.setUserEmail();
    this.setAllNavItems();
    this.getSideNavMenuItems();
    this.organizationFacade.getAllOrganizations();
    this.authenticationFacade.getNonAdminUserList();
    this.adminStatus = this.checkAdminUserStatus(this.user$);
    this.user$.subscribe((u) => {
      if (u) {
        this.termFacade.getAllTerms();
        this.userId = u.id;
      }
    });
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setAllNavItems();
      this.getSideNavMenuItems();
    });
    this.pinnedItemsFacade.getPinnedIngestions();
  }

  setUserEmail(): void {
    this.user$.subscribe((user) => {
      this.userEmail = user?.email;
      this.userName = user?.['name'];
      this.userGroups = user?.['groups'];
    });
  }

  setAllNavItems(): void {
    this.allNavMenuItems = this.navigationService.getAllMenuItems();
  }
  setActivatedParent(parentItems: IMenuItem[]): void {
    this.activatedParent = parentItems.find((item) => {
      return this.router.url.includes(item.state);
    });
  }

  getSideNavMenuItems(): IMenuItem[] {
    this.setActivatedParent(this.allNavMenuItems);
    this.AllSideMenuItems = this.navigationService.getSubMenuItems(this.activatedParent);
    return this.AllSideMenuItems;
  }

  logoutUser(): void {
    this.authenticationFacade.logout();
    this.user$.subscribe((d) => {
      if (!d) {
        this.router.navigate(['/auth/login']);
      }
    });
  }

  checkAdminUserStatus(user: Observable<User>): boolean {
    let adminStatus: boolean;
    user.subscribe((userData) => {
      if (userData?.groups?.[0]?.name === 'athena_admin') {
        adminStatus = true;
      } else {
        adminStatus = false;
      }
    });
    return adminStatus;
  }
}
