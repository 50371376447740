import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, of, concatMap } from 'rxjs';
import { ILoginResponse } from '../../models/login';
import { LoginService } from '../../services/login.service';
import { AuthenticationApiActions, AuthenticationUiActions } from '../actions';
import { User } from '../../models/user';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService
  ) {}

  getNonAdminUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationUiActions.GetNonAdminUserList),
      concatMap(() => {
        return this.loginService.getNonAdminUserList().pipe(
          map((results: User[]) => {
            return AuthenticationApiActions.GetNonAdminUserListSuccess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(AuthenticationApiActions.LogUserInError(err));
          })
        );
      })
    )
  );

  postLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationUiActions.LogUserIn),
      concatMap(({ apiRequest }) => {
        return this.loginService.postUserLogin(apiRequest).pipe(
          map((results: ILoginResponse) => {
            return AuthenticationApiActions.LogUserInSuccess({
              user: results.user,
            });
          }),
          catchError((err) => {
            return of(AuthenticationApiActions.LogUserInError(err));
          })
        );
      })
    )
  );

  googleOauth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationUiActions.GoogleOauthLogUserIn),
      concatMap(({ outhIdToken }) => {
        return this.loginService.googleOauthLogin(outhIdToken).pipe(
          map((results: ILoginResponse) => {
            return AuthenticationApiActions.GoogleOauthLogUserInSuccess({
              user: results.user,
            });
          }),
          catchError((err) => {
            return of(AuthenticationApiActions.GoogleOauthLogUserInError(err));
          })
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationUiActions.LogUserOut),
      concatMap(() => {
        return this.loginService.logOut().pipe(
          map(() => {
            return AuthenticationApiActions.LogUserOutSuccess();
          }),
          catchError((err) => {
            return of(AuthenticationApiActions.LogUserOutError(err));
          })
        );
      })
    )
  );

  timeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationUiActions.UserTimeOut),
      concatMap(() => {
        return this.loginService.logOut().pipe(
          map(() => {
            return AuthenticationApiActions.UserTimeOutSuccess();
          })
        );
      })
    )
  );
}
