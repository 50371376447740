import { Component, Input } from '@angular/core';
import { Term } from '../../models/Term';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-terms-view',
  templateUrl: './terms-view.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          height: '150px',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '50px',
          opacity: 0.8,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class TermsViewComponent {
  @Input() allTermData: Term[];

  termsState = {};

  toggle(id: number) {
    if (this.termsState[id]) {
      this.termsState[id] = !this.termsState[id];
    } else {
      this.termsState = {};
      this.termsState[id] = true;
    }
  }
  scrollToLatest() {
    const lastEle = document.getElementsByClassName('term-card');
    lastEle[lastEle.length - 1].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
