import { createAction, props } from '@ngrx/store';
import { Organization } from '../../models/Organization';

enum OrganizationApiActionTypes {
  GetAllOrganizationsSucess = '[Organization API] Get Organizations Success',
  GetAllOrganizationsError = '[Organization API] Get Organizations Error',

  CreateNewOrganizationSucess = '[Organization API] Get Organizations Success',
  CreateNewOrganizationError = '[Organization API] Get Organizations Error',

  UpdateOrganizationSucess = '[Organization API] Get Organizations Success',
  UpdateOrganizationError = '[Organization API] Get Organizations Error',
}

export const GetAllOrganizationsSucess = createAction(
  OrganizationApiActionTypes.GetAllOrganizationsSucess,
  props<{ data: Organization[] }>()
);
export const GetAllOrganizationsError = createAction(OrganizationApiActionTypes.GetAllOrganizationsError);

export const CreateNewOrganizationSucess = createAction(
  OrganizationApiActionTypes.CreateNewOrganizationSucess,
  props<{ data: Organization }>()
);
export const CreateNewOrganizationError = createAction(OrganizationApiActionTypes.CreateNewOrganizationError);

export const UpdateOrganizationSucess = createAction(
  OrganizationApiActionTypes.UpdateOrganizationSucess,
  props<{ data: Organization }>()
);
export const UpdateOrganizationError = createAction(OrganizationApiActionTypes.UpdateOrganizationError);
