import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuItem } from '../../models/IMenuItem';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';
import { PinnedDataIngestionService } from '../../services/pinned-data-ingestions.service';
import { MatDialog } from '@angular/material/dialog';
import { NavOutDialogBoxComponent } from '../nav-out-dialog-box/nav-out-dialog-box.component';
import { User } from 'src/app/views/authentication/models/user';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          width: '170px',
        })
      ),
      state(
        'closed',
        style({
          width: '65px',
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class TopNavComponent {
  @Input() activatedParent: IMenuItem;
  @Input() items: IMenuItem[];
  @Input() navCollapsed: boolean;
  @Input() pinnedList: DataIngestion[];
  @Input() user: User;

  pinnedIngestions: DataIngestion[];
  filteredMenus: IMenuItem[];
  constructor(
    public router: Router,
    public dialog: MatDialog
  ) {}

  public navigateToIngestionReport(ingestionID: number): void {
    this.router.navigateByUrl(`dashboard/report/${ingestionID}`);
  }

  public isActivated(route: string): boolean {
    return this.router.url.includes(route);
  }

  public openDialog(menuItem: IMenuItem): void {
    if (menuItem?.name === 'USERS') {
      const dialogRef = this.dialog.open(NavOutDialogBoxComponent, {});
    }
  }
}
