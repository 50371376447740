import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PageState } from '../../models/PageState';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Organization } from '../../models/Organization';

@Component({
  selector: 'app-org-form',
  templateUrl: './org-form.component.html',
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          'max-height': '500px',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          height: '50px',
          opacity: 0.8,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class OrgFormComponent {
  @Output() outputOrgData = new EventEmitter<Organization>();
  @Input() loadingStatus: PageState;
  isOpen = true;

  orgForm = new FormGroup({
    i_id: new FormControl(undefined, Validators.compose([])),
    i_num: new FormControl(undefined, Validators.compose([])),
    short_name: new FormControl('', Validators.compose([Validators.required])),
    i_banner: new FormControl(undefined, Validators.compose([])),
    i_opeid: new FormControl(undefined, Validators.compose([])),
    long_name: new FormControl(undefined, Validators.compose([Validators.required])),
    i_sas_name: new FormControl(undefined, Validators.compose([Validators.required])),
    i_inst_type: new FormControl(undefined, Validators.compose([])),
    inactive: new FormControl(undefined, Validators.compose([Validators.required])),
  });

  submitOrgForm(): void {
    this.outputOrgData.emit({
      i_id: this.orgForm.get('i_id').value,
      i_num: this.orgForm.get('i_num').value,
      short_name: this.orgForm.get('short_name').value,
      i_banner: this.orgForm.get('i_banner').value,
      i_opeid: this.orgForm.get('i_opeid').value,
      long_name: this.orgForm.get('long_name').value,
      i_sas_name: this.orgForm.get('i_sas_name').value,
      i_inst_type: this.orgForm.get('i_inst_type').value,
      inactive: this.orgForm.get('inactive').value,
    });
  }
}
