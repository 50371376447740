import { Action, on, createReducer } from '@ngrx/store';
import { Organization } from '../../models/Organization';
import * as SharedActions from '../actions';
import { PageState } from '../../models/PageState';

const initialState: OrganizationDataState = {
  organizations: [],
  orgLoadingStatus: null,
};

export interface OrganizationDataState {
  organizations: Organization[];
  orgLoadingStatus: PageState;
}

export const reducer = createReducer(
  initialState,
  on(SharedActions.OrganizationUIActions.GetAllOrganizations, (state, payload) => {
    return {
      organizations: [],
      orgLoadingStatus: PageState.Loading,
    };
  }),
  on(SharedActions.OrganizationUIActions.CreateNewOrganization, (state, payload) => {
    return {
      ...state,
      orgLoadingStatus: PageState.Loading,
    };
  }),
  on(SharedActions.OrganizationUIActions.UpdateNewOrganization, (state, payload) => {
    return {
      ...state,
      orgLoadingStatus: PageState.Loading,
    };
  }),
  on(SharedActions.OrganizationAPIActions.GetAllOrganizationsSucess, (state, payload) => {
    return {
      organizations: payload.data,
      orgLoadingStatus: PageState.Loaded,
    };
  }),
  on(SharedActions.OrganizationAPIActions.CreateNewOrganizationSucess, (state, payload) => {
    return {
      organizations: [...state.organizations, payload.data],
      orgLoadingStatus: PageState.Loaded,
    };
  }),
  on(SharedActions.OrganizationAPIActions.UpdateOrganizationSucess, (state, payload) => {
    let index = -1;
    state.organizations.forEach((log, idx) => {
      if (log.id == payload.data.id) {
        index = idx;
      }
    });
    return {
      organizations: [...state.organizations.slice(0, index), payload.data, ...state.organizations.slice(index + 1)],
      orgLoadingStatus: PageState.Loaded,
    };
  })
);

export function OrganizationReducer(state: OrganizationDataState, action: Action) {
  return reducer(state, action);
}
