import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedDataModuleState } from './reducers';

export const sharedStateKey = 'shared';
const getSharedFeatureState = createFeatureSelector<SharedDataModuleState>(sharedStateKey);

export const selectOrganizationDataState = createSelector(getSharedFeatureState, (state) => state.organizationsState);

export const selectTermDataState = createSelector(getSharedFeatureState, (state) => state.termsState);

export const selectPinnedDataState = createSelector(getSharedFeatureState, (state) => state.pinnedState);
