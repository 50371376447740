import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { PinnedAPIActions, PinnedUIActions } from '../actions';
import { concatMap, map, catchError, of } from 'rxjs';
import { PinnedDataIngestionService } from '../../services/pinned-data-ingestions.service';
import { DataIngestion } from 'src/app/views/dashboard/models/data-ingestion';

@Injectable()
export class PinnedItemsEffects {
  constructor(
    private actions$: Actions,
    private pinnedDataIngestionService: PinnedDataIngestionService
  ) {}

  pinnedIngestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PinnedUIActions.GetAllPinned),
      concatMap(() => {
        return this.pinnedDataIngestionService.getPinnedDataIngestionInfo().pipe(
          map((results: DataIngestion[]) => {
            return PinnedAPIActions.GetAllPinnedIngestionsSucess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(PinnedAPIActions.GetAllPinnedIngestionsError());
          })
        );
      })
    )
  );
}
