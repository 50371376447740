import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Term, FileList } from 'src/app/shared/models/Term';

import { MatMenu } from '@angular/material/menu';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-file-upload',
  templateUrl: './admin-file-upload.component.html',
})
export class AdminFileUploadComponent implements OnChanges {
  @Output() outputOnUpload = new EventEmitter<any>();
  @Input() allTermData: Term[];
  @Input() fileUploadStatus: boolean;
  @Input() preSelectedTermId: number;
  @Input() isOpen: boolean;
  @ViewChild('fileDropZone') stuffDiv: ElementRef;

  termDropdownForm: FormGroup;
  files: NgxFileDropEntry[] = [];
  fileLists: FileList[] = [];
  selectedTerm: Term | undefined;
  missingFiles: string[] = [];
  errors: string[] = [];
  uploadedFileNames: string[] = [];
  allFilesIncluded = false;
  filesChecked = false;
  fiscalYearArray = this.generateFiscalYearArray();
  typeTwoUploadCheck: boolean;
  reqFileObj = {};
  receivedDateMap = {};
  uploadStarted: boolean;
  orgAllowedTermArr = [];
  todayDate = new FormControl(new Date());

  constructor(
    private matMenu: MatMenu,
    public router: Router
  ) {
    this.termDropdownForm = new FormGroup({
      term: new FormControl({ value: '', disabled: false }, Validators.compose([])),
      fiscalYear: new FormControl(''),
    });
  }

  ngOnChanges(): void {
    if (this.preSelectedTermId && this.allTermData.length > 0) {
      this.onTermSelect({ target: { value: this.preSelectedTermId.toString() } });
      this.termDropdownForm.get('term')?.setValue(this.preSelectedTermId);
      this.termDropdownForm.get('term')?.disable();
    }
    if (this.fileUploadStatus) {
      setTimeout(() => {
        this.fileLists = [];
        this.errors = [];
        this.selectedTerm = undefined;
        this.uploadStarted = false;
        this.fileUploadStatus = false;
      }, 2000);
    }
  }

  dropped(files: NgxFileDropEntry[]) {
    const tempErrors = [];
    const tempFiles = [];
    for (const file of files) {
      if (this.selectedTerm.data_type === 'type_2') {
        const newFile: FileList = {
          term: this.selectedTerm,
          file_name: file.relativePath.split('.')[0],
        };
        this.fileLists.length === 0 ? (this.fileLists = []) : null;
        this.fileLists.push(newFile);
        tempFiles.push(file);
      } else if (this.isFileUploaded(file)) {
        // tempErrors.push('File "' + file.relativePath + '" has already been uploaded.');
      } else if (!this.isFileAcceptable(file) || this.isFileListExceeded()) {
        tempErrors.push('Please update the file name to match the files required');
      } else {
        tempFiles.push(file);
        this.reqFileObj[file.relativePath.split('.')[0]] = true;
        this.uploadedFileNames.push(file.relativePath);
      }
    }
    this.errors = [...tempErrors];
    this.files.push(...tempFiles);
    this.checkMissingFiles();
    this.validateSubmission();
  }

  onTermSelect(event: any) {
    this.files = [];
    const searchForTermId = this.preSelectedTermId ?? parseInt(event.target.value, 10);
    this.selectedTerm = this.allTermData.find((term) => term.id === searchForTermId);
    this.fileLists = this.selectedTerm?.file_lists || [];
    this.errors = [];
    this.uploadedFileNames = [];
    //lets do sometihng diff for type 2
    this.fileLists.forEach((req) => {
      this.missingFiles.push(req?.file_name);
      this.reqFileObj[req?.file_name] = false;
    });
  }

  checkMissingFiles() {
    this.missingFiles = this.fileLists
      .map((file) => file.file_name)
      .filter(
        (fileName) =>
          !this.files.some((file) => file.relativePath.split('.')[0].toLowerCase() === fileName.toLowerCase())
      );
  }

  validateSubmission() {
    if (this.fileLists.length !== this.files.length) {
      return;
    }
    let allFilesValid = this.fileLists.every((fileList) => {
      return this.files.some((file) => file.relativePath.includes(fileList.file_name));
    });
    if (this.selectedTerm?.data_type === 'type_2') {
      allFilesValid = true;
    }
    if (allFilesValid) {
      this.errors = [];
      this.filesChecked = true;
    } else {
      this.errors = ['Invalid File Name.'];
    }
  }

  checkFiscalTSInput(timestamp: string) {
    this.receivedDateMap[this.fileLists.length - 1] = timestamp;
  }

  isFileUploaded(file: NgxFileDropEntry): boolean {
    return this.uploadedFileNames.includes(file.relativePath);
  }

  isFileAcceptable(file: NgxFileDropEntry): boolean {
    return this.fileLists.some((fileList) => file.relativePath.includes(fileList.file_name));
  }

  isFileListExceeded(): boolean {
    return this.fileLists.length <= this.files.length;
  }

  isFileGood(fileName: string): boolean {
    for (let i = 0; i < this.files.length; i++) {
      for (let j = 0; j < this.fileLists.length; j++) {
        if (this.files[i].relativePath.split('.')[0].toLowerCase() === fileName.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  }

  deleteFile(fileName: string) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].relativePath.split('.')[0].toLowerCase() === fileName.toLowerCase()) {
        const file = this.files[i];
        this.uploadedFileNames.splice(this.uploadedFileNames.indexOf(file.relativePath), 1);
        this.files.splice(i, 1);
        this.checkMissingFiles();
      }
    }
  }

  updateFiscalTSMap(event, fileIdx: number) {
    this.receivedDateMap[fileIdx] = event.target.value;
  }

  closeMenu() {
    this.matMenu.closed.emit();
    this.refreshSubmission();
    this.router.navigate(['dashboard/main']);
  }

  onSubmitFiles() {
    if (this.selectedTerm.data_type === 'type_2' && !this.checkFiscalYearValueAndReceivedDataMap()) {
      this.typeTwoUploadCheck = false;
    } else {
      this.uploadStarted = true;
      this.outputOnUpload.emit({
        files: this.files,
        term: this.selectedTerm,
        received_date_map: this.receivedDateMap,
        fiscal_year: this.termDropdownForm.get('fiscalYear').value,
      });
    }
  }

  refreshSubmission() {
    this.termDropdownForm.reset({ term: '' });
    this.files = [];
    this.selectedTerm = undefined;
    this.fileLists = [];
    this.errors = [];
    this.typeTwoUploadCheck = undefined;
    if (this.preSelectedTermId) {
      this.termDropdownForm.get('term')?.enable();
      this.preSelectedTermId = undefined;
    }
  }

  calculateDynamicButtonPosition(element: ElementRef): number {
    if (element) {
      const stuffDivHeight = element.nativeElement.offsetHeight;
      let multiplier = 0.98;
      stuffDivHeight >= 650 ? (multiplier = 1.1) : multiplier;
      return stuffDivHeight * multiplier;
    }
    return 90;
  }

  generateFiscalYearArray(): string[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    const range = 20;
    for (let i = currentYear; i >= currentYear - range; i--) {
      const range = `${i}-${i + 1}`;
      years.push(`${range}`);
    }
    return years;
  }

  checkFiscalYearValueAndReceivedDataMap(): boolean {
    if (
      this.termDropdownForm.get('fiscalYear').value !== '' &&
      Object.keys(this.receivedDateMap).length === this.files.length
    ) {
      return true;
    } else {
      return false;
    }
  }
  returnNewDate(): string {
    return new Date().toDateString();
  }
}
