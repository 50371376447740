export enum MenuItemTypes {
  link = 'link',
  dropDown = 'dropDown',
}

export interface IMenuItem {
  type?: MenuItemTypes;
  group?: any;
  name?: string;
  state?: string;
  sub?: IMenuItem[];
  permissions?: string[];
}
