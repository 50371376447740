import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataIngestion } from '../models/data-ingestion';

@Injectable()
export class DataIngestionService {
  constructor(private apiService: ApiService) {}
  readonly ingestionRoot = '/data_ingestions';

  getAllList(): Observable<DataIngestion[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, '');
    return this.apiService.get(fullAPIUrl);
  }
  checkNGetList(filter_criteria: object): Observable<DataIngestion[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, 'check/');
    return this.apiService.post(fullAPIUrl, filter_criteria);
  }
  getUserOwnList(user_id: number): Observable<DataIngestion[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, '', { user_id });
    return this.apiService.get(fullAPIUrl);
  }
  getOrgOwnList(org_id: number): Observable<DataIngestion[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, '', { org_id });
    return this.apiService.get(fullAPIUrl);
  }
  getSingleIngestion(ingestion_id: number): Observable<DataIngestion> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `${ingestion_id}/`);
    return this.apiService.get(fullAPIUrl);
  }
  createDataIngestion(ingestion: DataIngestion): Observable<DataIngestion> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, '');
    return this.apiService.post(fullAPIUrl, ingestion);
  }

  patchDataIngestion(jsonPayload: object): Observable<DataIngestion> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `${jsonPayload['id']}/`);
    return this.apiService.patch(fullAPIUrl, jsonPayload);
  }

  sendPubSubMsgStagingReadyIngestions(ingestionIdObj: object): Observable<DataIngestion[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, 'bundle_submission_pubsub_msg/');
    return this.apiService.post(fullAPIUrl, ingestionIdObj);
  }

  flipLoadingState(): void {
    return;
  }
  getSummaryReport(ing_id: number): Observable<any> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `summary_report/${ing_id}/`);
    return this.apiService.get(fullAPIUrl, {});
  }

  downloadSummaryReportZip(ing_id: number): Observable<Blob> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `summary_report/${ing_id}/`);
    return this.apiService.get(fullAPIUrl, { responseType: 'blob' });
  }

  downloadIngestionReport(ingestionId: number): Observable<Blob> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `download_report/${ingestionId}/`);
    return this.apiService.get(fullAPIUrl, { responseType: 'blob' });
  }
}
