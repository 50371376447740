import { createAction, props } from '@ngrx/store';

enum DataIngestionUIActionTypes {
  GetAllDataIngestions = '[Ingestion UI] Get All Ingestions',
  GetAllIInstances = '[Instance UI] Get All Ingestion Instances',
  GetUserOwnedIInstances = '[Instance UI] Get User Owned Ingestion Instances',
  GetOrgOwnedIInstances = '[Instance UI] Get Organization Owned Ingestion Instances',
  PatchIInstance = '[Instance UI] Patch an Instance',
  GetSingleDataIngestion = '[Ingestion UI] Get a Single Ingestion',
  GetIngestionSearchWord = '[Ingestion UI] Get Ingestion Search Word',
  PatchIngestion = '[Ingestion UI] Patch an Ingestion',
  GetLoadingState = '[Ingestion UI] Get Loading State',
}

export const GetAllDataIngestions = createAction(DataIngestionUIActionTypes.GetAllDataIngestions);
export const GetAllIInstances = createAction(
  DataIngestionUIActionTypes.GetAllIInstances,
  props<{ data_type: string }>()
);
export const GetUserOwnedIInstances = createAction(
  DataIngestionUIActionTypes.GetUserOwnedIInstances,
  props<{ user_id: number }>()
);
export const GetOrgOwnedIInstances = createAction(
  DataIngestionUIActionTypes.GetOrgOwnedIInstances,
  props<{ org_id: number }>()
);

export const GetSingleDataIngestion = createAction(
  DataIngestionUIActionTypes.GetSingleDataIngestion,
  props<{ ingestion_id: number }>()
);

export const PatchIInstance = createAction(DataIngestionUIActionTypes.PatchIInstance, props<{ jsonPayload: object }>());

export const PatchIngestion = createAction(DataIngestionUIActionTypes.PatchIngestion, props<{ jsonPayload: object }>());

export const GetIngestionSearchWord = createAction(
  DataIngestionUIActionTypes.GetIngestionSearchWord,
  props<{ search_word: string }>()
);

export const GetLoadingState = createAction(DataIngestionUIActionTypes.GetLoadingState);
