import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginContainerComponent } from './containers/login/login.container';
import { LoginComponent } from './components/login/login.component';
import { RouterModule } from '@angular/router';
import { AuthenticationRoutes } from './authentication.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { AuthenticationFacade } from './facade/authentication.facade';
import { ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { AuthenticationReducer } from './state/reducer/authentication.reducer';
import { authenticationStateKey } from './state';
import { AuthenticationEffects } from './state/effects/authentication.effects';
import { EffectsModule } from '@ngrx/effects';
import { AuthenticationModuleState } from './state/reducer';
import { localStorageSync } from 'ngrx-store-localstorage';

const moduleEffects = [AuthenticationEffects];

const reducers: ActionReducerMap<AuthenticationModuleState> = {
  authentication: AuthenticationReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({ keys: ['user'], rehydrate: true, removeOnUndefined: true })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [LoginContainerComponent, LoginComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    SharedModule,
    HttpClientModule,
    StoreModule.forFeature(authenticationStateKey, reducers.authentication, {
      metaReducers,
    }),
    EffectsModule.forFeature(moduleEffects),
  ],
  providers: [LoginService, AuthenticationFacade],
})
export class AuthenticationModule {}
