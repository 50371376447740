import { IMenuItem, MenuItemTypes } from 'src/app/shared/models/IMenuItem';
import { BaseModuleRegistraion, IModuleRegistration } from 'src/app/shared/moduleRegistration/base.registration';

export const DashboardRegistration: IModuleRegistration = class extends BaseModuleRegistraion {
  static readonly title = 'Status';
  static readonly breadcrumb = 'Status';

  static getMenu(): IMenuItem[] {
    return [
      {
        name: 'Status',
        type: MenuItemTypes.dropDown,
        state: 'dashboard/main',
        sub: [
          {
            name: 'Main',
            type: MenuItemTypes.link,
            state: 'dashboard/main',
          },
          {
            name: 'Report',
            type: MenuItemTypes.link,
            state: 'dashboard/report',
          },
          {
            name: 'SummaryReport',
            type: MenuItemTypes.link,
            state: 'dashboard/summary_report',
          },
          {
            name: 'MPIMapping',
            type: MenuItemTypes.link,
            state: 'dashboard/mpi_mapping',
          },
        ],
      },
    ];
  }
};
