import { Component } from '@angular/core';
import { OrganizationFacade } from '../../facades/organization-facade';

@Component({
  selector: 'app-orgs-view-container',
  templateUrl: './orgs-view.container.html',
})
export class OrgsViewContainerComponent {
  allOrganizationData$ = this.organizationFacade.allOrganizationData$;

  constructor(private organizationFacade: OrganizationFacade) {}
}
