<div
  (click)="$event.stopPropagation()"
  class="z-50 px-8 w-[600px] min-h-[440px] max-h-[700px] flex flex-col bg-athena-bg-dark-selected"
>
  <div class="flex flex-row justify-end relative left-7 bottom-1 h-7">
    <button closeMenu (click)="closeMenu()" class="text-athena-bg-light-gry mt-2">
      <mat-icon class="text-base font-bold">close</mat-icon>
    </button>
  </div>
  <div class="flex flex-col justify-between mb-3">
    <h2 class="text-md text-yellow-400 font-bold mb-3">Upload Job Files</h2>
    <form [formGroup]="termDropdownForm">
      <select
        id="term"
        formControlName="term"
        class="text-athena-bg-dark p-2 rounded-md font-bold mr-5 border-r-8 border-white"
        (change)="onTermSelect($event)"
        [disabled]="preSelectedTermId !== undefined"
      >
        <option value="" disabled>Select a term</option>
        <option *ngFor="let term of allTermData" [value]="term.id">
          {{ term.data_type[term.data_type.length - 1] }} {{ term.term_name }}
        </option>
      </select>
      <select
        *ngIf="selectedTerm?.data_type === 'type_2'"
        id="fiscalYear"
        formControlName="fiscalYear"
        class="text-athena-bg-dark p-2 rounded-md font-bold border-r-8 border-white"
        [ngClass]="{ 'border-athena-red border-2': typeTwoUploadCheck === false }"
      >
        <option *ngFor="let year of fiscalYearArray" [ngValue]="year">{{ year.split('-').join(' - ') }}</option>
      </select>
    </form>
  </div>
  <div #fileDropZone class="">
    <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" class="!p-0">
      <ng-template #template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <!-- Place border below here and put the ubtton outside of the dashed border area -->
        <div class="h-full w-full flex flex-col relative">
          <div
            class="max-h-[500px] bg-athena-bg-dark border-black border-[3px] px-4 py-5 border-dashed min-h-[260px] h-[500px] my-2 overflow-y-auto"
          >
            <h2 class="text-white text-lg text-center font-bold mb-3">
              {{ selectedTerm ? 'Uploading ' + fileLists?.length + ' Files' : 'Please select a term.' }}
            </h2>
            <div
              *ngFor="let file of fileLists; let i = index"
              class="w-full h-[40px] pl-5 mb-2 bg-athena-bg-dark-selected text-white text-sm rounded-md flex items-center justify-between"
            >
              <span>{{ file.file_name }}</span>
              <div
                [ngClass]="{ 'bg-black': selectedTerm.data_type === 'type_2' }"
                class="uploader-status-date-box flex gap-5 items-center px-2 h-full rounded-r-md"
              >
                <mat-progress-bar
                  mode="indeterminate"
                  class="!w-16"
                  *ngIf="isFileGood(file?.file_name) && uploadStarted === true && !fileUploadStatus"
                ></mat-progress-bar>
                <span *ngIf="uploadStarted && fileUploadStatus === true" class="ml-2 text-green-500"
                  ><mat-icon>check_circle</mat-icon></span
                >
                <mat-form-field
                  *ngIf="this.selectedTerm?.data_type === 'type_2' && !fileUploadStatus"
                  [ngClass]="{ 'border-athena-red border-2': typeTwoUploadCheck === false }"
                >
                  <input
                    matInput
                    [matDatepicker]="picker"
                    (dateChange)="updateFiscalTSMap($event, i)"
                    [value]="todayDate.value"
                  />
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <button
                  *ngIf="this.reqFileObj[file?.file_name] && isFileGood(file?.file_name)"
                  (click)="deleteFile(file?.file_name)"
                  class="ml-2 text-red-500 mr-5"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="selectedTerm && missingFiles.length > 0" class="w-full flex justify-end mt-[10px]">
            <button
              (click)="openFileSelector()"
              class="bg-athena-bg-blue text-white text-xs rounded-sm py-1 px-7 tracking-wide uppercase font-bold hover:bg-athena-bg-dark hover:text-white shadow-md"
            >
              Select Files
            </button>
          </div>
          <div class="w-full flex justify-end gap-3 mt-[10px]">
            <p *ngFor="let error of errors" class="text-xs text-athena-orange font-bold whitespace-nowrap mr-3">
              {{ error }}
            </p>
            <button
              *ngIf="selectedTerm && fileLists && missingFiles.length === 0"
              (click)="openFileSelector()"
              class="bg-athena-bg-blue text-white text-xs rounded-sm py-1 px-7 tracking-wide uppercase font-bold hover:bg-athena-bg-dark hover:text-white shadow-md"
            >
              Select Files
            </button>
            <button
              *ngIf="selectedTerm && fileLists && missingFiles.length === 0"
              [disabled]="!filesChecked"
              (click)="onSubmitFiles()"
              class="bg-athena-bg-blue text-white text-xs rounded-sm py-1 px-7 tracking-wide uppercase font-bold hover:bg-athena-bg-dark hover:text-white shadow-md"
            >
              Submit Files
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>
