<button
  (click)="openMenu()"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  class="flex items-center border-2 rounded-full px-1 pr-2 text-xs text-gray-500 hover:bg-athena-bg-blue hover:text-white"
>
  <span class="flex justify-center items-center gap-1 text-xs whitespace-nowrap"
    ><mat-icon class="text-sm flex relative top-[2px]">add_circle_outline</mat-icon>
    Upload Attachment File
  </span>
</button>

<mat-menu class="admin-drop-down-mat-menu" #menu="matMenu" xPosition="before" [hasBackdrop]="false">
  <app-admin-attachment-file-upload-container></app-admin-attachment-file-upload-container>
</mat-menu>
