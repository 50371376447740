<mat-drawer-container class="w-[170px] h-screen" [@openClose]="!navCollapsed ? 'open' : 'closed'">
  <div class="flex flex-col justify-between bg-athena-bg-light-gry h-full items-center">
    <div class="w-full">
      <div class="py-2 text-lg font-extrabold border-white border-b-[1px] text-gray-500 text-center h-[50px]">
        {{ navCollapsed ? 'A' : 'ATHENA' }}
      </div>
      <button
        *ngFor="let menuItem of items"
        class="top-menu-button sml-btn !rounded-none uppercase text-white py-1 !text-[10px] w-full h-[50px] gap-3 !bg-athena-bg-dark border-white border-b-[1px] flex items-center"
        [ngClass]="{ activated: isActivated(menuItem.state) }"
        [routerLink]="menuItem.state"
        (click)="openDialog(menuItem)"
      >
        <mat-icon class="ml-2 text-[20px]" *ngIf="menuItem.name === 'Status'">view_list</mat-icon>
        <mat-icon class="ml-2 text-[20px]" *ngIf="menuItem.name === 'Messaging'">edit_square</mat-icon>
        <mat-icon class="ml-2 text-[20px]" *ngIf="menuItem.name === 'Archive'">archive</mat-icon>
        <mat-icon class="ml-2 text-[20px]" *ngIf="menuItem.name === 'USERS'">people_outline</mat-icon>
        <mat-icon class="ml-2 text-[20px]" *ngIf="menuItem.name === 'Tools'">miscellaneous_services</mat-icon>
        {{ !navCollapsed ? menuItem.name : '' }}
      </button>

      <div class="p-4 mt-2">
        <p class="text-athena-bg-darker-light-gray text-xs mb-1">Pinned</p>
        <ol>
          <li
            *ngFor="let ing of pinnedList"
            class="text-[11px] text-athena-bg-dark cursor-pointer mb-1"
            (click)="navigateToIngestionReport(ing.id)"
          >
            {{ ing?.term?.['term_name'] }} {{ ing?.organization?.['short_name'] }}
          </li>
        </ol>
      </div>
    </div>
    <p class="text-[10px] text-gray-500 mb-5">2024 Utah Data Research Center</p>
  </div>
</mat-drawer-container>
