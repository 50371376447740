import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class MenuService {
  public menuEmitter: EventEmitter<string | null> = new EventEmitter<string | null>();

  open(menuId: string): void {
    this.menuEmitter.emit(menuId);
  }
  close(): void {
    this.menuEmitter.emit(null);
  }
}
