import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataIngestion } from '../models/data-ingestion';
import { IngestionInstance } from '../models/ingestion-instance';

@Injectable()
export class IInstanceService {
  constructor(private apiService: ApiService) {}
  readonly ingestionRoot = '/i_instances';

  getAllList(type: string): Observable<IngestionInstance[]> {
    const date = new Date();
    const currYear = date.getFullYear();
    const fullAPIUrl = this.apiService.buildFullURL(
      this.ingestionRoot,
      `?data_type=${type}&submission_year=${currYear}`
    );
    return this.apiService.get(fullAPIUrl);
  }
  getAllArchiveList(year: number): Observable<IngestionInstance[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `?submission_year=${year}`);
    return this.apiService.get(fullAPIUrl);
  }

  getSingleIngestion(ingestion_id: number): Observable<DataIngestion> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `${ingestion_id}/`);
    return this.apiService.get(fullAPIUrl, {});
  }
  createInstance(completed: string): Observable<DataIngestion> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, '');
    return this.apiService.post(fullAPIUrl, { completed });
  }
  patchIInstance(jsonPayload: object): Observable<IngestionInstance> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `${jsonPayload['id']}/`);
    return this.apiService.patch(fullAPIUrl, { completed: jsonPayload['completed'] });
  }
  ldsDataCleanUp(instanceId: number): Observable<IngestionInstance> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, `${instanceId}/lds_cleanup`);
    return this.apiService.delete(fullAPIUrl);
  }
  getHistoricalYearConsumptionData(): Observable<number[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.ingestionRoot, 'historical_consumption_yrs/');
    return this.apiService.get(fullAPIUrl);
  }
}
