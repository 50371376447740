import { createAction, props } from '@ngrx/store';
import { Term } from '../../models/Term';

enum TermUIActionTypes {
  GetAllTerms = '[Term UI] Get All Terms',
  CreateNewTerm = '[Term UI Create New Term',
}

export const GetAllTerms = createAction(TermUIActionTypes.GetAllTerms);
export const CreateNewTerm = createAction(TermUIActionTypes.CreateNewTerm, props<{ term: Term }>());
