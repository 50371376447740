import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/shared/models/Organization';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class OrganizationService {
  constructor(private apiService: ApiService) {}
  readonly organizationRoot = '/organizations';

  getAllOrgList(): Observable<Organization[]> {
    const fullAPIUrl = this.apiService.buildFullURL(this.organizationRoot, '');
    return this.apiService.get<Organization[]>(fullAPIUrl);
  }

  createOrg(org: Organization): Observable<Organization> {
    const fullAPIUrl = this.apiService.buildFullURL(this.organizationRoot, '');
    return this.apiService.post<Organization>(fullAPIUrl, org);
  }
  updateOrg(data: object): Observable<Organization> {
    const fullAPIUrl = this.apiService.buildFullURL(this.organizationRoot, `${data['id']}/`);
    return this.apiService.patch<Organization>(fullAPIUrl, data);
  }
}
