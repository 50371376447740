import { Injectable } from '@angular/core';
import { AuthenticationFacade } from 'src/app/views/authentication/facade/authentication.facade';
import { NotifySystemRegistration } from 'src/app/views/notify-system/notify-system-registration';
import { IMenuItem, MenuItemTypes } from '../models/IMenuItem';
import { DashboardRegistration } from 'src/app/views/dashboard/dashboard-registration';
import { MessageRegistration } from 'src/app/views/message/message-registration';
import { ArchiveRegistration } from 'src/app/views/archive/archive-registration';

@Injectable()
export class NavigationService {
  constructor(private authFacade: AuthenticationFacade) {}
  public user$ = this.authFacade.user$;

  getAllMenuItems(): IMenuItem[] {
    const mainMenu: IMenuItem[] = [
      ...DashboardRegistration.getMenu(),
      ...MessageRegistration.getMenu(),
      ...ArchiveRegistration.getMenu(),
    ];
    this.user$.subscribe((d) => {
      if (d.groups?.[0]?.name === 'athena_admin' && mainMenu.length < 5) {
        mainMenu.push({
          name: 'USERS',
          type: MenuItemTypes.link,
          state: 'users',
        });
        mainMenu.push(...NotifySystemRegistration.getMenu());
      }
    });
    return mainMenu;
  }

  getSubMenuItems(parentItem: IMenuItem): IMenuItem[] {
    return parentItem?.sub;
  }
}
