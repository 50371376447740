import { ThisReceiver } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthenticationFacade } from 'src/app/views/authentication/facade/authentication.facade';
import { LoginService } from 'src/app/views/authentication/services/login.service';

@Injectable()
export class AuthGaurdService {
  constructor(
    public router: Router,
    private auth: LoginService,
    private authenticationFacade: AuthenticationFacade
  ) {}

  helperRefreshToken() {
    this.auth.postRefreshToken().subscribe({
      error: (error: any) => {
        this.authenticationFacade.userTimeOut();
        this.authenticationFacade.user$.subscribe((user) => {
          if (!user) {
            this.router.navigate(['/auth/login/']);
          }
        });
      },
    });
  }

  canActivate() {
    return this.auth.postVerifyUser().pipe(
      map((results: any) => {
        return true;
      }),
      catchError((err) => {
        this.helperRefreshToken();
        return of(err);
      })
    );
  }
}
