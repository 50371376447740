<div class="flex min-h-screen bg-athena-bg-login-blue">
  <div class="m-auto font-bold">
    <div class="flex flex-col justify-center items-center mb-10">
      <div>
        <p class="text-athena-bg-dark text-sm font-normal m-auto mb-2 text-center">Welcome to</p>
        <h1 class="text-athena-bg-blue text-6xl text-center mt-2">ATHENA</h1>
      </div>
    </div>
    <mat-card class="bg-white px-20 rounded-3xl shadow-custom">
      <mat-card-header class="m-auto font-bold text-2xl mb-6">
        <div class="flex flex-col justify-around items-center">
          <h1 class="text-athena-bg-blue text-2xl text-center mb-2">SIGN IN</h1>
          <p class="text-athena-bg-darker-light-gray text-sm font-normal text-center">
            Please sign-in with your Google account to continue.
          </p>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="pb-0 flex flex-col justify-center items-center">
          <button
            class="w-[250px] mb-4 rounded-full border font-medium border-gray-400 px-4 py-3 text-sm"
            (click)="ssoSignin()"
          >
            Sign In with Google
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
