import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-out-dialog-box',
  templateUrl: './nav-out-dialog-box.component.html',
})
export class NavOutDialogBoxComponent {
  djangoAdminURL = environment.apiURL.slice(0, environment.apiURL.length - 2) + 'admin';

  constructor(public dialogRef: MatDialogRef<NavOutDialogBoxComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
