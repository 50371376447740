import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { TermAPIActions, TermUIActions } from '../actions';
import { concatMap, map, catchError, of } from 'rxjs';
import { TermService } from '../../services/terms.service';
import { Term } from '../../models/Term';

@Injectable()
export class TermEffects {
  constructor(
    private actions$: Actions,
    private termService: TermService
  ) {}

  terms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermUIActions.GetAllTerms),
      concatMap(() => {
        return this.termService.getAllTermList().pipe(
          map((results: Term[]) => {
            return TermAPIActions.GetAllTermsSucess({
              data: results,
            });
          }),
          catchError((err) => {
            return of(TermAPIActions.GetAllTermsError());
          })
        );
      })
    )
  );

  createTerm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermUIActions.CreateNewTerm),
      concatMap(({ term }) => {
        return this.termService.createNewTerm(term).pipe(
          map((result: Term) => {
            return TermAPIActions.CreateNewTermSuccess({
              data: result,
            });
          }),
          catchError((err) => {
            return of(TermAPIActions.CreateNewTermError());
          })
        );
      })
    )
  );
}
