<div class="flex justify-between px-5">
  <h2 mat-dialog-title>Redirect Message</h2>
  <button (click)="onNoClick()">
    <mat-icon class="text-sm font-bold text-athena-bg-darker-light-gray">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p class="px-6 mb-8">You will be directed to Django Admin Application.</p>
  <div class="flex justify-end px-6">
    <a
      (click)="onNoClick()"
      class="hover:bg-athena-bg-dark-selected py-1 px-3 rounded-md bg-athena-bg-dark text-white"
      target="_blank"
      [href]="djangoAdminURL + '/users/user'"
      >Open New Browser</a
    >
  </div>
</mat-dialog-content>
