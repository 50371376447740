import { Component } from '@angular/core';
import { TermFacade } from '../../facades/term-facade';
import { Term } from '../../models/Term';

@Component({
  selector: 'app-terms-view-container',
  templateUrl: './terms-view.container.html',
})
export class TermsViewContainerComponent {
  allTermData$ = this.termFacade.allTermData$;

  constructor(private termFacade: TermFacade) {}
}
