<div (click)="stopPropagation($event)">
  <h2 mat-dialog-title>Are you still there?</h2>
  <mat-dialog-content>Press below to resume application use.</mat-dialog-content>
  <mat-dialog-actions class="!flex !justify-end mr-3">
    <button
      (click)="refreshBrowser()"
      class="hover:bg-athena-bg-light-gry hover:text-athena-text-blue py-2 px-3 rounded-md bg-athena-bg-light-gray-title"
    >
      Continue
    </button>
  </mat-dialog-actions>
</div>
