import { createAction, props } from '@ngrx/store';
import { IDjangoErrors } from 'src/app/shared/models/DjangoErrors';
import { User } from 'src/app/views/authentication/models/user';

enum AuthenticationApiActionTypes {
  LogUserInSuccess = '[Authentication API] Log User In Success',
  LogUserInError = '[Authentication API] Log User In Error',
  GoogleOauthLogUserInSuccess = '[Authentication API] Google Oauth Log User In Success',
  GoogleOauthLogUserInError = '[Authentication API] Google Oauth Log User In Error',
  LogUserOutSuccess = '[Authentication API] Log User Out Success',
  LogUserOutError = '[Authentication API] Log User Out Error',
  UserTimeOutSuccess = '[Authentication API] User Time Out Success',
  UserTimeOutError = '[Authentication API] User Time Out Error',

  GetNonAdminUserListSuccess = '[Users API] Get Non Admin User List Success',
  GetNonAdminUserListError = '[Users API] Get Non Admin User List Error',
}

export const LogUserInSuccess = createAction(AuthenticationApiActionTypes.LogUserInSuccess, props<{ user: User }>());

export const LogUserInError = createAction(
  AuthenticationApiActionTypes.LogUserInError,
  props<{ error: IDjangoErrors }>()
);

export const LogUserOutSuccess = createAction(AuthenticationApiActionTypes.LogUserOutSuccess);

export const LogUserOutError = createAction(
  AuthenticationApiActionTypes.LogUserOutError,
  props<{ error: IDjangoErrors }>()
);

export const UserTimeOutSuccess = createAction(AuthenticationApiActionTypes.UserTimeOutSuccess);
export const UserTimeOutError = createAction(
  AuthenticationApiActionTypes.UserTimeOutError,
  props<{ error: IDjangoErrors }>()
);

export const GoogleOauthLogUserInSuccess = createAction(
  AuthenticationApiActionTypes.GoogleOauthLogUserInSuccess,
  props<{ user: User }>()
);

export const GoogleOauthLogUserInError = createAction(
  AuthenticationApiActionTypes.GoogleOauthLogUserInError,
  props<{ error: IDjangoErrors }>()
);

export const GetNonAdminUserListSuccess = createAction(
  AuthenticationApiActionTypes.GetNonAdminUserListSuccess,
  props<{ data: User[] }>()
);
export const GetNonAdminUserListError = createAction(AuthenticationApiActionTypes.GetNonAdminUserListError);
