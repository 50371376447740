import { createAction, props } from '@ngrx/store';
import { ILoginParams } from '../../models/login';

enum AuthenticationUiActionTypes {
  LogUserIn = '[Authentication UI] Log User In',
  LogUserOut = '[Authentication UI] Log User Out',
  UserTimeOut = '[Authentication UI] Time User Out',
  GoogleOauthLogUserIn = '[Authentication UI] Log User In Using Google Oauth',
  GetNonAdminUserList = '[Users UI] Get All Non Admin User List',
}

export const LogUserIn = createAction(AuthenticationUiActionTypes.LogUserIn, props<{ apiRequest: ILoginParams }>());

export const LogUserOut = createAction(AuthenticationUiActionTypes.LogUserOut);
export const UserTimeOut = createAction(AuthenticationUiActionTypes.UserTimeOut);

export const GetNonAdminUserList = createAction(AuthenticationUiActionTypes.GetNonAdminUserList);

export const GoogleOauthLogUserIn = createAction(
  AuthenticationUiActionTypes.GoogleOauthLogUserIn,
  props<{ outhIdToken: string }>()
);
