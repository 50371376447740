import { Component, Input } from '@angular/core';
import { OrganizationFacade } from '../../facades/organization-facade';
import { Organization } from '../../models/Organization';

@Component({
  selector: 'app-org-form-container',
  templateUrl: './org-form.container.html',
})
export class OrgFormContainerComponent {
  @Input() scrollToLatest: () => void;
  loadingStatus$ = this.organizationFacade.orgLoadingStatus$;

  constructor(private organizationFacade: OrganizationFacade) {}

  public submitOrgForm(org: Organization) {
    this.organizationFacade.createOrg(org);
    setTimeout(this.scrollToLatest, 500);
  }
}
