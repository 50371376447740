import { Component, Input, OnInit } from '@angular/core';
import { PageState } from '../../models/PageState';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { interval } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  animations: [
    trigger('boxSize', [
      state(
        'small',
        style({
          height: '25px',
        })
      ),
      state(
        'large',
        style({
          height: '35px',
          background: 'gray',
          opacity: 0.7,
        })
      ),
      transition('small <=> large', animate('0.5s linear')),
    ]),
  ],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() loadingStatus: PageState;
  boxState = ['small', 'small', 'small'];

  ngOnInit(): void {
    this.timedAnimation(0);

    setTimeout(() => {
      this.timedAnimation(1);
    }, 350);

    setTimeout(() => {
      this.timedAnimation(2);
    }, 700);

    setInterval(() => {
      this.timedAnimation(0);

      setTimeout(() => {
        this.timedAnimation(1);
      }, 350);

      setTimeout(() => {
        this.timedAnimation(2);
      }, 700);
    }, 1050);
  }

  toggleSize(index: number) {
    this.boxState[index] = this.boxState[index] === 'small' ? 'large' : 'small';
  }

  timedAnimation(index: number) {
    setTimeout(() => {
      this.toggleSize(index);
      setTimeout(() => {
        this.toggleSize(index);
      }, 350);
    }, 350);
  }
}
