<div>
  <app-org-form-container></app-org-form-container>
</div>

<mat-card
  class="mt-2 mx-2 overflow-hidden"
  *ngFor="let org of allOrganizationData"
  [@openClose]="orgsState[org.id] ? 'open' : 'closed'"
>
  <mat-card-header>
    <div class="flex justify-between w-full">
      <mat-card-title [ngClass]="{ 'text-sm': '!termsState[term.id]' }">{{ org.long_name }}</mat-card-title>
      <div>
        <mat-icon *ngIf="orgsState[org.id]" class="text-sm cursor-pointer">settings</mat-icon>
        <mat-icon *ngIf="orgsState[org.id]" class="text-sm cursor-pointer" (click)="toggle(org.id)"
          >arrow_drop_up</mat-icon
        >
      </div>
      <mat-icon *ngIf="!orgsState[org.id]" class="text-sm cursor-pointer" (click)="toggle(org.id)"
        >arrow_drop_down</mat-icon
      >
    </div>
  </mat-card-header>
  <mat-card-content>
    <table class="text-sm">
      <tr>
        <td class="whitespace-nowrap">Code :</td>
        <td>{{ org.short_name }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst ID :</td>
        <td>{{ org.i_id }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst SAS Code :</td>
        <td>{{ org.i_sas_name }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst Num :</td>
        <td>{{ org.i_num }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst Banner :</td>
        <td>{{ org.i_banner }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst OPEID :</td>
        <td>{{ org.i_opeid }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst SAS Code :</td>
        <td>{{ org.i_sas_name }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inst Type :</td>
        <td>{{ org.i_inst_type }}</td>
      </tr>
      <tr>
        <td class="whitespace-nowrap">Inactive :</td>
        <td>{{ org.inactive }}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
